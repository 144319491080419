import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useOrderCategoriesList } from '../../../consts/db-values';
import { Controller, useForm } from 'react-hook-form';
import { MediaInput, MediaInputValue } from '../../common/media-input';
import { uploadMediaInputFiles } from '../../../services/file-service';
import { api } from '../../../services/api';
import { AlertService } from '../../../services/alert.service';
import {
  decimalValidation,
  fiveDecimalPlacesValidation,
  intValidation,
  required,
} from '../../../utils/validations';
import { InputWrapper } from '../../common/input-wrapper';
import { PurchaseOrderCategory } from './purchase-order-receipt-confirmation-modal';
import { PurchaseOrder } from './receipt-confirmation';
import { useStorageLocation } from '../../../api/delivery';
import CreatableSelect from 'react-select/creatable';

type ReceivedEditFormProps = {
  id: string;
  defaultValues: {
    productFiles: MediaInputValue[];
    category?: PurchaseOrderCategory;
    weight?: string;
    volume?: string;
    count?: number;
    places?: number;
    storageLocation?: string;
    receiptComment?: string;
  };
  onSave: (data: PurchaseOrder) => void;
  onCancel: () => void;
};

export const ReceivedEditForm: React.FC<ReceivedEditFormProps> = (props) => {
  const intl = useIntl();
  const ORDER_CATEGORIES_LIST = useOrderCategoriesList();
  const { register, control, handleSubmit, formState } = useForm<{
    productFiles: MediaInputValue[];
    category: PurchaseOrderCategory;
    weight: string;
    volume: string;
    places: number;
    count: number;
    storageLocation?: { value: string; label: string };
    receiptComment?: string;
  }>({
    defaultValues: {
      ...props.defaultValues,
      storageLocation: {
        value: props.defaultValues.storageLocation,
        label: props.defaultValues.storageLocation,
      },
    },
  });

  const { data: storageLocations } = useStorageLocation();
  const [inputStorage, setInputStorage] = useState(
    props.defaultValues.storageLocation,
  );

  const onSubmit = handleSubmit(async (form) => {
    const productFilesResponse = await uploadMediaInputFiles(form.productFiles);
    const { data }: { data: { purchaseOrder: PurchaseOrder } } = await api.put(
      `/purchase-order/v2/${props.id}/receipt-confirmation`,
      {
        places: Number(form.places),
        weight: form.weight,
        volume: form.volume,
        category: form.category,
        storageLocation: form.storageLocation?.value,
        receiptComment: form.receiptComment,
        productFiles: productFilesResponse.map((response) => ({
          id: response.file.id,
        })),
        count: form.count ? Number(form.count) : null,
      },
    );

    props.onSave(data.purchaseOrder);
    AlertService.success();
  });

  function handleCancel() {
    props.onCancel();
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="d-flex flex-row justify-content-between">
        <div className="fs-18 fw-600 color-gray-450">
          <FormattedMessage
            defaultMessage="Получение на склад"
            id="receiptConfirmation.label.receiptAtTheWarehouse"
            description="Заголовок на странице"
          />
        </div>
        <div>
          <button
            className="btn btn-link text-decoration-none"
            disabled={formState.isSubmitting}
          >
            {formState.isSubmitting ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Сохранить"
                id="receiptConfirmation.label.save"
                description="Надпись на кнопке"
              />
            )}
          </button>
          <button
            className="btn btn-link text-decoration-none"
            type="button"
            disabled={formState.isSubmitting}
            onClick={handleCancel}
          >
            <FormattedMessage
              defaultMessage="Отменить"
              id="receiptConfirmation.label.cancel"
              description="Надпись на кнопке"
            />
          </button>
        </div>
      </div>
      <Controller
        control={control}
        rules={{ ...required }}
        name="productFiles"
        render={({ field, fieldState }) => (
          <InputWrapper
            theme="light"
            className="mt-3"
            title={intl.formatMessage({
              defaultMessage: 'Фотографии товаров при получении',
              id: 'input.label.photosOfGoodsOnReceipt',
              description: 'Заголовок поля ввода',
            })}
            required
            error={fieldState.error?.message}
          >
            <MediaInput {...field} className="mt-2" multiple />
          </InputWrapper>
        )}
      />
      <InputWrapper
        theme="light"
        className="mt-3"
        title={intl.formatMessage({
          defaultMessage: 'Категория товара',
          id: 'input.input-label.goodsCategory',
          description: 'Заголовок поля ввода',
        })}
        error={formState.errors.category?.message}
      >
        <select
          className="form-select"
          {...register('category', { ...required })}
        >
          <option value="" hidden>
            <FormattedMessage
              defaultMessage="Выберите категорию товара"
              id="purchaseOrderModal.placeholder-label.selectAGoodsCategory"
              description="Заголовок выпадающего меню"
            />
          </option>
          {ORDER_CATEGORIES_LIST.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </InputWrapper>
      <div className="mt-3">
        <div className="row">
          <div className="col-6">
            <InputWrapper
              theme="light"
              title={intl.formatMessage({
                defaultMessage: 'Вес при получении',
                id: 'receiptConfirmation.input-label.weightUponReceipt',
                description: 'Заголовок поля ввода',
              })}
              error={formState.errors.weight?.message}
            >
              <div className="input-group">
                <input
                  className="form-control"
                  type="number"
                  min={0}
                  step={0.01}
                  {...register('weight', { ...required, ...decimalValidation })}
                />
                <span className="input-group-text">
                  <FormattedMessage
                    defaultMessage="кг"
                    id="input.label.kg"
                    description="Единица измерения"
                  />
                </span>
              </div>
            </InputWrapper>
          </div>
          <div className="col-6">
            <InputWrapper
              theme="light"
              title={intl.formatMessage({
                defaultMessage: 'Объем при получении',
                id: 'receiptConfirmation.input-label.volumeUponReceipt',
                description: 'Заголовок поля ввода',
              })}
              error={formState.errors.volume?.message}
            >
              <div className="input-group">
                <input
                  className="form-control"
                  type="number"
                  min={0}
                  step={0.00001}
                  {...register('volume', {
                    ...required,
                    ...fiveDecimalPlacesValidation,
                  })}
                />
                <span className="input-group-text">
                  <FormattedMessage
                    defaultMessage="м³"
                    id="input.label.length"
                    description="Единица измерения"
                  />
                </span>
              </div>
            </InputWrapper>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <InputWrapper
              theme="light"
              title={intl.formatMessage({
                defaultMessage: 'Кол-во мест',
                id: 'deliveryAssemblyModal.input-label.amountOfPlaces',
                description: 'Заголовок поля ввода',
              })}
              error={formState.errors.places?.message}
            >
              <div className="input-group">
                <input
                  className="form-control"
                  type="number"
                  min={1}
                  step={1}
                  {...register('places', {
                    ...required,
                    ...intValidation,
                  })}
                />
              </div>
            </InputWrapper>
          </div>
          <div className="col-6">
            <InputWrapper
              theme="light"
              title={intl.formatMessage({
                defaultMessage: 'Кол-во товара',
                id: 'input.label.quantityOfGoods',
                description: 'Заголовок поля ввода',
              })}
            >
              <div className="input-group">
                <input
                  className="form-control"
                  type="number"
                  min={0}
                  step={1}
                  {...register('count')}
                />
                <span className="input-group-text">шт</span>
              </div>
            </InputWrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <InputWrapper
              theme="light"
              className="mt-3"
              title={intl.formatMessage({
                defaultMessage: 'Место хранения',
                id: 'input.label.storageLocation',
                description: 'Заголовок поля ввода',
              })}
              required
              error={formState.errors.storageLocation?.message}
            >
              <Controller
                name="storageLocation"
                control={control}
                rules={{
                  ...required,
                  maxLength: {
                    value: 20,
                    message: 'Максимальная длина 20 символов',
                  },
                }}
                render={({ field }) => (
                  <CreatableSelect
                    {...field}
                    options={storageLocations?.map((location) => ({
                      value: location,
                      label: location,
                    }))}
                    placeholder=""
                    formatCreateLabel={(value) => `Добавить "${value}"`}
                    onInputChange={(newValue) => {
                      setInputStorage(newValue);
                    }}
                    onChange={(newValue) => {
                      setInputStorage('');
                      field.onChange(newValue);
                    }}
                    onBlur={() => {
                      if (inputStorage) {
                        field.onChange({
                          value: inputStorage,
                          label: inputStorage,
                        });
                      }
                    }}
                  />
                )}
              />
            </InputWrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <InputWrapper
              theme="light"
              title={intl.formatMessage({
                defaultMessage: 'Комментарий',
                id: 'input.input-label.receiptComment',
                description: 'Заголовок поля ввода',
              })}
              className="mt-3"
              error={formState.errors.receiptComment?.message}
              required
            >
              <div className="input-group">
                <textarea
                  className="form-control"
                  {...register('receiptComment')}
                />
              </div>
            </InputWrapper>
          </div>
        </div>
      </div>
    </form>
  );
};
