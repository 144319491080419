import React, { useEffect, useRef, useState } from 'react';

import { useModalClose } from '../../../utils/useModalClose';
import { ModalProps } from '../../../services/modals.service';
import JsPdf from 'jspdf';
import { domToCanvas } from 'modern-screenshot';

type InvoceForPaymentModalProps = ModalProps & {
  transaction?: string;
};

export const InvoceForPaymentModal: React.FC<InvoceForPaymentModalProps> = ({
  close,
  transaction,
}) => {
  const modalRef = useModalClose(close);
  const [htmlContent, setHtmlContent] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string>('');
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const fetchHtml = async () => {
      try {
        const response = await fetch(
          `/api/transaction/${transaction}/download`,
        );
        const contentDisposition = response.headers.get('Content-Disposition');
        if (contentDisposition) {
          const matches = contentDisposition.match(/filename="([^"]+)"/);
          if (matches && matches[1]) {
            setFileName(decodeURIComponent(matches[1]).replace(/\.html$/, ''));
          }
        }
        if (!response.ok) {
          throw new Error('Ошибка загрузки HTML');
        }
        const html = await response.text();
        setHtmlContent(html);
      } catch (error) {
        console.error(error);
      }
    };

    fetchHtml();
  }, [transaction]);

  const downloadHtmlAsPdf = async (withSigning: boolean = true) => {
    try {
      const iframe = iframeRef.current;
      if (!iframe) return;
      const clonedIFrame = iframe.cloneNode(true) as HTMLIFrameElement;
      clonedIFrame.style.visibility = 'hidden';
      clonedIFrame.style.position = 'absolute';
      clonedIFrame.style.top = '0';
      clonedIFrame.style.left = '0';
      clonedIFrame.onload = async () => {
        const iframeContent = clonedIFrame.contentWindow?.document?.body;
        if (!iframeContent) return;

        if (!withSigning) {
          const images = iframeContent.querySelectorAll('.image-signing');

          images.forEach((img) => {
            img.remove();
          });
        }
        const canvas = await domToCanvas(iframeContent, {
          height: 1122,
          width: 792,
          style: {
            minWidth: '792px',
            minHeight: '1122px',
            paddingLeft: '0px',
          },
        });
        const a4Width = 595.28; // Ширина A4 в точках
        const a4Height = 841.89; // Высота A4 в точках

        const image = canvas.toDataURL('image/png');
        const pdf = new JsPdf('p', 'pt', [a4Width, a4Height]);

        pdf.addImage(image, 'PNG', -10, -10, a4Width, a4Height);
        pdf.save(fileName + '.pdf');
        document.body.removeChild(clonedIFrame);
      };
      document.body.appendChild(clonedIFrame);
    } catch (error) {
      console.error('Failed to generate PDF:', error);
    }
  };

  return (
    <div
      className="modal-dialog"
      ref={modalRef}
      style={{ maxWidth: '850px', width: '100%' }}
    >
      <div className="modal-content">
        <div className="modal-body">
          <div className="d-flex align-items-center">
            <button type="button" className="btn p-0 ms-auto" onClick={close}>
              <i className="bi bi-x fs-36 color-gray-600" />
            </button>
          </div>
          <div className="flex items-center justify-center rounded-3xl bg-white">
            {htmlContent ? (
              <div className="flex w-full flex-col rounded-3xl lg:flex-row">
                <iframe
                  ref={iframeRef}
                  srcDoc={htmlContent}
                  style={{ height: '80vh', width: '100%' }}
                ></iframe>
              </div>
            ) : (
              <p>Загрузка...</p>
            )}
          </div>
        </div>
        <div className="modal-footer justify-content-center">
          <button
            type="submit"
            className="btn btn-success"
            onClick={async () => {
              await downloadHtmlAsPdf();
            }}
          >
            Скачать с подписью
          </button>
          <button
            type="submit"
            className="btn btn-success"
            onClick={async () => {
              await downloadHtmlAsPdf(false);
            }}
          >
            Скачать без подписи
          </button>
        </div>
      </div>
    </div>
  );
};
