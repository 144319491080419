import './gallery.scss';
import React, { PropsWithChildren } from 'react';
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import { InitDetail } from 'lightgallery/lg-events';

export type GalleryProps = {
  className?: string;
};

export const Gallery: React.FC<PropsWithChildren<GalleryProps>> = ({
  children,
  className,
}) => {
  const lightGallery = React.useRef<InitDetail['instance'] | null>(null);

  const onInit = React.useCallback((detail: InitDetail) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  React.useEffect(() => {
    lightGallery.current?.refresh();
  }, [children]);

  return (
    <LightGallery
      speed={500}
      plugins={[lgZoom]}
      mobileSettings={{
        showCloseIcon: true,
      }}
      elementClassNames={className}
      onInit={onInit}
      licenseKey={
        process.env.NODE_ENV === 'development' ? undefined : undefined
      }
      showZoomInOutIcons={true}
      actualSize={false}
    >
      {children}
    </LightGallery>
  );
};
