import { Header } from '../../common/header.component';
import { Controller, useForm } from 'react-hook-form';
import { required } from '../../../utils/validations';
import { AlertService } from '../../../services/alert.service';
import { useNavigate } from 'react-router-dom';
import { CleanPurchaseOrder } from '../../../export-types/cleaned-types';
import React, { useState } from 'react';
import { InputWrapper } from '../../common/input-wrapper';
import { User, UserSelect } from '../../common/user-select';
import {
  getCurrencySymbol,
  getFileV2ApiUrl,
  toMoney,
} from '../../../utils/common.utils';
import { ModalsService } from '../../../services/modals.service';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  useDeliveryAddresses,
  useDeliveryAvailablePurchases,
  PackageGroup,
  useDeliveryCalculation,
  useDeliveriesMethodForClient,
  usePackageMethodsForClient,
} from '../../../api/delivery';
import ReactSelect from 'react-select';
import { useOrderCategories } from '../../../consts/db-values';
import { usePurchaseOrdersByInternalId } from '../../../api/use-purchase-orders-by-internal-id';
import classNames from 'classnames';
import { AddPackageGroupModal } from './add-package-group-modal.component';
import Toggle from 'rsuite/Toggle';
import { Radio, RadioGroup } from 'rsuite';
import { DeliveryAddressService } from '../../../services/delivery-address.service';
import { AddressFormModal } from './address-form-modal.component';
import { useDebouncedValue } from '@mantine/hooks';
import { DeliveryOrderService } from '../../../services/delivery-order.service';
import { AddressMenu } from '../../common/address-menu.component';

export const DeliveryOrderCreateScreen = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const handleSubmit = async (data: DeliveryOrderFormData) => {
    if (
      data.packagesGroup.flatMap((group) => group.orders).length !=
      data.purchaseOrders.length
    ) {
      AlertService.error(
        intl.formatMessage({
          defaultMessage: 'Выберите упаковку для всех заказов',
          id: 'DeliveryOrderCreate.label.selectPackagingForAllOrders',
          description: 'Информация о заказе',
        }),
      );
      return;
    }
    if (data.packagesGroup.some((group) => group.package.length == 0)) {
      AlertService.error(
        intl.formatMessage({
          defaultMessage: 'Выберите упаковку для всех заказов',
          id: 'DeliveryOrderCreate.label.selectPackagingForAllOrders',
          description: 'Информация о заказе',
        }),
      );
      return;
    }
    const { data: response } = await DeliveryOrderService.createDeliveryOrder(
      data,
      data.user.internalId as number,
    );
    AlertService.success(
      intl.formatMessage({
        defaultMessage: 'Доставка создана.',
        id: 'DeliveryOrderCreate.label.deliveryHasBeenCreated',
        description: 'Информация о заказе',
      }),
    );

    navigate(`/deliveries/delivery-order/${response.deliveryOrder.id}`);
  };

  return (
    <>
      <Header />
      <div className="container" style={{ marginTop: -10 }}>
        <h1>Оформить доставку товаров</h1>
        <DeliveryOrderForm onSubmit={handleSubmit} />
      </div>
    </>
  );
};

const DeliveryOrderForm = (props: DeliveryOrderFormProps) => {
  const intl = useIntl();

  const {
    control,
    resetField,
    formState: { isSubmitting },
    handleSubmit,
    watch,
    getValues,
    setValue,
  } = useForm<DeliveryOrderFormData>({
    defaultValues: {
      purchaseOrders: [],
      packagesGroup: [],
    },
  });

  const onSubmit = handleSubmit(async function (form) {
    await props.onSubmit(form);
  });

  const [
    purchaseOrders,
    user,
    packagesGroup,
    deliveryMethod,
    localDeliveryMethod,
    address,
  ] = watch([
    'purchaseOrders',
    'user',
    'packagesGroup',
    'deliveryMethod',
    'localDeliveryMethod',
    'address',
  ]);

  const { data: availablePurchaseOrders } = useDeliveryAvailablePurchases(
    user?.internalId,
    purchaseOrders,
  );

  const { data: allPurchaseOrders } = usePurchaseOrdersByInternalId({
    take: 100,
    status: ['receivedInStock'],
    internalId: user?.internalId?.toString(),
    all: true,
  });

  const purchaseOrderForSelect = React.useMemo(
    () =>
      purchaseOrders.filter(
        (purchase) =>
          !packagesGroup.some((group) => group.orders.includes(purchase)),
      ),
    [purchaseOrders, packagesGroup],
  );

  const onDeletePurchaseOrder = React.useCallback(
    (index: number) => {
      const newPurchaseOrders = [...getValues('purchaseOrders')];
      newPurchaseOrders.splice(index, 1);
      resetField('deliveryMethod');
      resetField('localDeliveryMethod');
      resetField('purchaseOrders', {
        defaultValue: newPurchaseOrders,
      });
      resetField('packagesGroup', {
        defaultValue: [],
      });
      setSelectedPurchaseForPackage([]);
    },
    [resetField, getValues],
  );

  const [selectedPurchaseForPackage, setSelectedPurchaseForPackage] =
    React.useState<string[]>(purchaseOrders);

  const { data: packageMethodsForGroups } =
    usePackageMethodsForClient(packagesGroup);

  const { data: methods } = useDeliveriesMethodForClient(purchaseOrders);

  const { data: deliveryAddresses, mutate: mutateAddresses } =
    useDeliveryAddresses(user?.internalId);

  const addresses = React.useMemo(() => {
    return deliveryAddresses?.filter(
      (address) => address.type !== 'pickupPoint',
    );
  }, [deliveryAddresses]);

  React.useEffect(() => {
    if (addresses && addresses.length > 0) {
      setValue('address', addresses[0].id);
    }
  }, [addresses, setValue]);

  const [debouncedPurchaseOrders] = useDebouncedValue(purchaseOrders, 250);
  const [debouncedDeliveryMethod] = useDebouncedValue(deliveryMethod, 250);
  const [debouncedLocalDeliveryMethod] = useDebouncedValue(
    localDeliveryMethod,
    250,
  );
  const [debouncedPackageGroup] = useDebouncedValue(packagesGroup, 250);

  const { data: calculation } = useDeliveryCalculation(
    debouncedPurchaseOrders,
    debouncedDeliveryMethod,
    debouncedLocalDeliveryMethod,
    debouncedPackageGroup,
    user?.internalId,
    address,
  );

  const [isCdek, setIsCdek] = useState<boolean>(false);

  return (
    <form
      className="row justify-content-center pt-4"
      id="deliveryOrderCreateForm"
      onSubmit={onSubmit}
    >
      <div className="col-7">
        <Controller
          control={control}
          name="user"
          rules={{
            ...required,
          }}
          render={({ field, fieldState }) => (
            <InputWrapper
              className="mb-3 fw-bold"
              title="Клиент"
              required
              error={fieldState.error?.message}
            >
              <div className="fw-400">
                <UserSelect
                  placeholder="Клиент"
                  isClearable={true}
                  {...field}
                  onChange={(user) => {
                    resetField('deliveryMethod');
                    resetField('localDeliveryMethod');
                    resetField('purchaseOrders', {
                      defaultValue: [],
                    });
                    resetField('packagesGroup', {
                      defaultValue: [],
                    });
                    field.onChange(user);
                  }}
                />
              </div>
            </InputWrapper>
          )}
        />
        {user ? (
          <>
            <div className="py-3 px-2 mb-4 border rounded-16">
              <Controller
                name="purchaseOrders"
                control={control}
                rules={{
                  required: intl.formatMessage({
                    defaultMessage: 'Выберите заказы для доставки',
                    id: 'DeliveryOrderCreate.input-label.selectOrdersForShiping',
                    description: 'Пояснение к полю ввода',
                  }),
                }}
                render={({ field, fieldState }) => {
                  return (
                    <InputWrapper
                      title={intl.formatMessage({
                        defaultMessage: 'Добавить заказ в доставку',
                        id: 'DeliveryOrderCreate.input-label.addAnOrderToDelivery',
                        description: 'Заголовок поля ввода',
                      })}
                      error={fieldState.error?.message}
                      className="fw-bold"
                    >
                      <ReactSelect
                        className="fw-400"
                        isDisabled={!user}
                        classNamePrefix="custom-select"
                        placeholder="Добавить в заказ"
                        options={
                          availablePurchaseOrders?.list.map((order) => ({
                            ...order,
                            label: order.internalId,
                          })) ?? []
                        }
                        formatOptionLabel={(order) => {
                          if (!order) return null;
                          return <PurchaseOrderItem order={order} />;
                        }}
                        value={null}
                        isSearchable={true}
                        onChange={(value) => {
                          resetField('deliveryMethod');
                          resetField('localDeliveryMethod');
                          field.onChange([...field.value, value?.id]);
                        }}
                        noOptionsMessage={() => (
                          <span>
                            {intl.formatMessage({
                              defaultMessage: 'Нет заказов',
                              id: 'DeliveryOrderCreate.input-label.noOrders',
                              description: 'Пояснение к полю ввода',
                            })}
                          </span>
                        )}
                      />
                    </InputWrapper>
                  );
                }}
              />
              <div className="py-3">
                {purchaseOrderForSelect.map((item, i) => {
                  const order = allPurchaseOrders?.items.find(
                    (element) => element.id === item,
                  );
                  if (!order) return null;

                  const selected = selectedPurchaseForPackage.includes(item);

                  return (
                    <div
                      className="bg-gray d-flex flex-row rounded-16 py-3 px-2 mb-4"
                      key={i}
                    >
                      <div
                        className="position-relative me-2 rounded-8"
                        style={{ width: '3rem' }}
                        onClick={() => {
                          selectedPurchaseForPackage.includes(item)
                            ? setSelectedPurchaseForPackage(
                                selectedPurchaseForPackage.filter(
                                  (purchase) => purchase != item,
                                ),
                              )
                            : setSelectedPurchaseForPackage([
                                ...selectedPurchaseForPackage,
                                item,
                              ]);
                        }}
                      >
                        <div
                          className={classNames(
                            'position-absolute d-flex rounded-8 w-100 h-100 justify-content-center align-items-center cursor-pointer',
                            !selected
                              ? 'hover-showing bg-black bg-opacity-75'
                              : 'bg-green-500',
                          )}
                        >
                          <i
                            className={classNames(
                              'bi bi-check-lg fs-3 color-white',
                            )}
                          />
                        </div>
                        <img
                          className="rounded-8 fit-cover w-100"
                          src={getFileV2ApiUrl(
                            order.productFilesV2[0].id,
                            '64x64',
                          )}
                          alt=""
                        />
                      </div>
                      <PurchaseOrderItem order={order} isLink={true} />
                      <div
                        onClick={() => onDeletePurchaseOrder(i)}
                        className="d-flex m-auto"
                      >
                        <i className="bi bi-trash color-gray-400 cursor-pointer hover-red fs-6" />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="d-flex ml-auto color-gray-400">
                <div
                  className={classNames(
                    'd-flex mright-auto hover-opacity',
                    selectedPurchaseForPackage.length &&
                      'color-green cursor-pointer',
                  )}
                  onClick={() => {
                    if (selectedPurchaseForPackage.length == 0) {
                      return;
                    }
                    setValue('packagesGroup', [
                      ...packagesGroup,
                      {
                        orders: selectedPurchaseForPackage,
                        package: [],
                      },
                    ]);
                    setSelectedPurchaseForPackage([]);
                  }}
                >
                  <i className="bi bi-box-seam mx-1" />
                  Создать упаковку
                </div>
                <div
                  className={classNames(
                    'd-flex mleft-auto ',
                    purchaseOrderForSelect.length &&
                      'color-green cursor-pointer hover-opacity',
                  )}
                  onClick={() => {
                    if (
                      purchaseOrderForSelect.length !==
                      selectedPurchaseForPackage.length
                    ) {
                      setSelectedPurchaseForPackage([
                        ...purchaseOrderForSelect,
                      ]);
                    } else {
                      setSelectedPurchaseForPackage([]);
                    }
                  }}
                >
                  {purchaseOrderForSelect.length !==
                  selectedPurchaseForPackage.length
                    ? 'Выбрать все'
                    : 'Снять отметки'}
                </div>
              </div>
            </div>
            <Controller
              control={control}
              rules={{
                required: intl.formatMessage({
                  defaultMessage: 'Выберите упаковку',
                  id: 'DeliveryOrderCreate.input-label.selectPackaging',
                  description: 'Пояснение к полю ввода',
                }),
              }}
              name="packagesGroup"
              render={({ field, fieldState }) => (
                <div className="mb-4">
                  {field.value.map((group, i) => (
                    <div key={i} className=" rounded-16 border p-4">
                      <div className="mb-2 d-flex align-items-center justify-between">
                        <div className="fs-5 fw-bold">
                          <FormattedMessage
                            defaultMessage="Упаковка №{value}"
                            id="DeliveryOrderCreate.title-label.packageNumber"
                            description="Заголовок на странице"
                            values={{
                              value: i + 1,
                            }}
                          />
                        </div>
                        <div className="mleft-auto d-flex gap-2 color-gray-400">
                          <div
                            className={classNames(
                              'hover-opacity',
                              purchaseOrders.filter(
                                (purchase) =>
                                  !packagesGroup.some((group) =>
                                    group.orders.includes(purchase),
                                  ),
                              ).length != 0 && 'color-green cursor-pointer',
                            )}
                            onClick={() => {
                              if (
                                !purchaseOrders.filter(
                                  (purchase) =>
                                    !packagesGroup.some((group) =>
                                      group.orders.includes(purchase),
                                    ),
                                ).length
                              ) {
                                return;
                              }
                              ModalsService.createModal(AddPackageGroupModal, {
                                data: allPurchaseOrders?.items,
                                availablePurchaseOrders: purchaseOrderForSelect,
                                purchaseOrders: [],
                                onSubmit: async (form) => {
                                  group.orders.push(...form.purchaseOrders);
                                  setValue('packagesGroup', field.value);
                                  AlertService.success(
                                    intl.formatMessage({
                                      defaultMessage: 'Товары были добавлены',
                                      id: 'DeliveryOrderCreate.btn-label.goodsHaveBeenAdded',
                                      description: 'Кнопка',
                                    }),
                                  );
                                },
                              });
                            }}
                          >
                            <FormattedMessage
                              defaultMessage="Добавить товары"
                              id="DeliveryOrderCreate.btn-label.addGoods"
                              description="Кнопка"
                            />
                          </div>
                          <div
                            className={`color-green hover-opacity cursor-pointer`}
                            onClick={() => {
                              const groups = packagesGroup;
                              groups.splice(i, 1);
                              setValue('packagesGroup', groups);
                            }}
                          >
                            <FormattedMessage
                              defaultMessage="Удалить"
                              id="DeliveryOrderCreate.btn-label.delete"
                              description="Кнопка"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mb-2 d-flex justify-content-start gap-2">
                        {group.orders.map((order) => {
                          const item = allPurchaseOrders?.items?.find(
                            (item) => item.id === order,
                          );
                          if (!item) return null;

                          return (
                            <div
                              key={order}
                              className="position-relative"
                              style={{ width: '64px', height: '64px' }}
                            >
                              <button
                                className="position-absolute top-0 start-0 bg-transparent hover-showing  w-100 h-100 d-flex justify-content-center align-items-center"
                                onClick={(e: {
                                  stopPropagation: () => void;
                                }) => {
                                  e.stopPropagation();
                                  group.orders.splice(
                                    group.orders.indexOf(order),
                                    1,
                                  );
                                  const groups = field.value;
                                  if (group.orders.length == 0) {
                                    groups.splice(i, 1);
                                  }
                                  setValue('packagesGroup', groups);
                                }}
                              >
                                <i className="bi bi-trash color-gray-400 cursor-pointer fs-3" />
                              </button>
                              <img
                                className="rounded-16 fit-cover me-2"
                                src={getFileV2ApiUrl(
                                  item.productFilesV2[0].id,
                                  '64x64',
                                )}
                                width={64}
                                height={64}
                                alt=""
                              />
                            </div>
                          );
                        })}
                      </div>

                      {packageMethodsForGroups?.[i] && (
                        <InputWrapper error={fieldState.error?.message}>
                          <div className="d-flex flex-column gap-2">
                            {packageMethodsForGroups[i].map((item) => (
                              <div
                                className="d-flex border  rounded-8 p-2 mb-2"
                                key={item.id}
                              >
                                <div>
                                  <div className="text-truncate fw-bold">
                                    {item.name}
                                  </div>
                                  <div className="text-truncate fs-6">
                                    {item.comment}
                                  </div>
                                </div>
                                <div className="mleft-auto d-flex ">
                                  <div className=" mx-1">
                                    {toMoney(Number(item.price))} $
                                  </div>
                                  <Toggle
                                    checked={group.package.includes(item.id)}
                                    onChange={(value: boolean) => {
                                      if (value) {
                                        group.package.push(item.id);
                                        field.onChange(field.value);
                                      } else {
                                        group.package.splice(
                                          group.package.indexOf(item.id),
                                          1,
                                        );
                                        field.onChange(field.value);
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </InputWrapper>
                      )}
                    </div>
                  ))}
                </div>
              )}
            />
            {methods?.delivery ? (
              <div className="py-3 px-2 mb-4 border rounded-16">
                <Controller
                  control={control}
                  rules={{
                    required: intl.formatMessage({
                      defaultMessage:
                        'Выберите метод доставки с транзитного склада',
                      id: 'DeliveryOrderCreate.input-label.selectDeliveryMethodFromTransitWarehouse',
                      description: 'Пояснение к полю ввода',
                    }),
                  }}
                  name="deliveryMethod"
                  render={({ field, fieldState }) => (
                    <InputWrapper
                      error={fieldState.error?.message}
                      title={intl.formatMessage({
                        defaultMessage: 'Доставка с транзитного склада',
                        id: 'DeliveryOrderCreate.label.deliveryFromTransitWarehouse',
                        description: 'Заголовок поля ввода',
                      })}
                      className="fw-bold"
                    >
                      {methods && methods.isMixedParcel ? (
                        <div className="w-100 my-2 fw-400">
                          <div className="w-100 rounded-16 bg-warning p-2">
                            <div>
                              <div className="fw-bold fs-4">
                                <FormattedMessage
                                  defaultMessage="Внимание!"
                                  id="DeliveryOrderCreate.label.attention"
                                  description="Информация о заказе"
                                />
                              </div>
                              <div>
                                <FormattedMessage
                                  defaultMessage="В доставке собраны разные категории. Тариф считается
                                            по большей."
                                  id="DeliveryOrderCreate.label.deliveryIncludesDifferentCategories"
                                  description="Информация о заказе"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <RadioGroup
                        value={field.value}
                        onChange={field.onChange}
                        className="p-1 gap-2 fw-400"
                      >
                        {methods.delivery.map((item) => (
                          <div
                            className="d-flex border  rounded-8 p-2 mb-2"
                            key={item.id}
                          >
                            <div className="d-flex items-start w-100 ">
                              <div className="text-container">
                                <div className="text-truncate  fw-bold ">
                                  {item.destination?.displayName}: {item.name}
                                </div>
                                <div className="fs-6">
                                  <FormattedMessage
                                    id="DeliveryOrderCreate.input-label.deliveryPeriod"
                                    defaultMessage="{deliveryPeriodFrom} — {deliveryPeriodTo} дней"
                                    values={{
                                      deliveryPeriodFrom:
                                        item.deliveryPeriodFrom,
                                      deliveryPeriodTo: item.deliveryPeriodTo,
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="min-w-100 text-end mt-1">
                              {toMoney(Number(item.price))} $
                            </div>
                            <Radio
                              key={item.id}
                              value={item.id}
                              className="mleft-auto fw-bold"
                            />
                          </div>
                        ))}
                      </RadioGroup>
                    </InputWrapper>
                  )}
                />
              </div>
            ) : null}

            {methods && methods.localDelivery ? (
              <div className="border rounded-16 py-3 px-2 mb-4">
                <Controller
                  control={control}
                  rules={{
                    required: intl.formatMessage({
                      defaultMessage: 'Выберите метод доставки',
                      id: 'DeliveryOrderCreate.input-label.selectDeliveryMethod',
                      description: 'Пояснение к полю ввода',
                    }),
                  }}
                  name="localDeliveryMethod"
                  render={({ field, fieldState }) => (
                    <InputWrapper
                      error={fieldState.error?.message}
                      title={intl.formatMessage({
                        defaultMessage: 'Транспортная компания',
                        id: 'DeliveryOrderCreate.label.shipingСompany',
                        description:
                          'Подзаголовок на странице/Информация о заказе',
                      })}
                      className="fw-bold"
                    >
                      <RadioGroup
                        value={field.value}
                        onChange={(value) => {
                          setIsCdek(
                            !!methods?.localDelivery.find(
                              (method) =>
                                method.uuid === value && method.isCdek,
                            ),
                          );
                          field.onChange(value);
                        }}
                        className="p-1 gap-2 fw-400"
                      >
                        {methods?.localDelivery.map((item) => (
                          <div
                            className="d-flex border rounded-8 p-2 mb-2"
                            key={item.id}
                          >
                            <div className="d-flex items-start w-100 ">
                              <div className="text-container d-flex align-items-center">
                                <div className="text-truncate  fw-bold ">
                                  {item.location?.displayName}: {item.name}
                                </div>
                              </div>
                            </div>
                            <Radio
                              key={item.uuid}
                              value={item.uuid}
                              className="mleft-auto fw-bold"
                            />
                          </div>
                        ))}
                      </RadioGroup>
                    </InputWrapper>
                  )}
                />
                <div className="mt-1 fs-6 color-gray-400">
                  <FormattedMessage
                    defaultMessage="На&nbsp;данный момент, автоматический расчет стоимости
                                    доставки недоступен. Вы&nbsp;можете рассчитать стоимость
                                    самостоятельно по&nbsp;калькуляторам на&nbsp;сайтах
                                    транспортных компаний."
                    id="DeliveryOrderCreate.label.automaticCostCalculationDeliveryIsNotAvailable"
                    description="Информация на странице"
                  />
                </div>
              </div>
            ) : null}

            {addresses ? (
              <div className="border rounded-16 py-3 px-2 mb-4">
                <Controller
                  control={control}
                  rules={{
                    required: intl.formatMessage({
                      defaultMessage: 'Выберите адрес доставки и получателя',
                      id: 'DeliveryOrderCreate.input-label.selectDeliveryAddressAndRecipient',
                      description: 'Пояснение к полю ввода',
                    }),
                  }}
                  name="address"
                  render={({ field, fieldState }) => (
                    <InputWrapper
                      error={fieldState.error?.message}
                      className="fw-bold"
                      title={intl.formatMessage({
                        defaultMessage: 'Адрес доставки и получатель',
                        id: 'DeliveryOrderCreate.label.deliveryAddressAndRecipient',
                        description: 'Подзаголовок на странице',
                      })}
                    >
                      <RadioGroup
                        value={field.value}
                        onChange={field.onChange}
                        className="p-1 fw-400"
                      >
                        {addresses.map((item) => (
                          <div
                            className="d-flex border  rounded-8 mb-3 p-2"
                            key={item.id}
                          >
                            <div className="d-flex items-start justify-between w-100">
                              <div className="mr-1">
                                <div className="text-truncate fw-bold ">
                                  {item.type === 'individual' ? (
                                    item.name
                                  ) : (
                                    <FormattedMessage
                                      defaultMessage="{companyName}, ИНН {companyInn}"
                                      id="DeliveryOrderCreate.input-label.TIN"
                                      description="Пояснение к полю ввода"
                                      values={{
                                        companyName: item.companyName,
                                        companyInn: item.companyInn,
                                      }}
                                    />
                                  )}
                                </div>
                                <div className="mt-2 text-truncate d-flex fs-6">
                                  {item.type === 'individual' ? (
                                    <FormattedMessage
                                      defaultMessage="паспорт {passport}, {city}, {phone}"
                                      id="DeliveryOrderCreate.input-label.passport"
                                      description="Пояснение к полю ввода"
                                      values={{
                                        passport: item.passport,
                                        city: item.city,
                                        phone: item.phone,
                                      }}
                                    />
                                  ) : (
                                    <div>
                                      {item.name}, {item.city}, {item.phone}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="mleft-auto fw-bold">
                                <AddressMenu
                                  isCdek={isCdek}
                                  address={item}
                                  internalId={user.internalId as number}
                                  mutate={mutateAddresses}
                                />
                                <Radio key={item.id} value={item.id} />
                              </div>
                            </div>
                          </div>
                        ))}
                      </RadioGroup>
                    </InputWrapper>
                  )}
                />
                <button
                  type="button"
                  className="btn border w-100 d-flex p-2 rounded-8 hover-border-green-400"
                  onClick={() => {
                    return ModalsService.createModal(AddressFormModal, {
                      onSubmit: async (form) => {
                        await DeliveryAddressService.createDeliveryAddress(
                          form,
                          user.internalId as number,
                        ).then(() => {
                          mutateAddresses();
                          AlertService.success(
                            intl.formatMessage({
                              defaultMessage: 'Адрес и получатель добавлены',
                              id: 'createOrder.label.addressAndRecipientAdded',
                              description: 'Информация о заказе',
                            }),
                          );
                        });
                      },
                      isCdek,
                    });
                  }}
                >
                  <FormattedMessage
                    defaultMessage="Добавить адрес и получателя"
                    id="createOrder.btn-label.addAddressAndRecipient"
                    description="Кнопка"
                  />
                  <i className="bi bi-plus mleft-auto"></i>
                </button>
              </div>
            ) : null}
          </>
        ) : null}
      </div>
      <div className="col-5">
        <div className="bg-gray-50 p-4 rounded-16">
          <h3 className="mb-3">Предварительный расчет</h3>
          <hr />
          <div className="d-flex flex-row justify-content-between mb-3 color-gray-400">
            <div className="fs-14 ">Вес</div>
            <FormattedMessage
              defaultMessage="{value} кг"
              id="order.label.kg"
              description="Единица измерения"
              values={{
                value: availablePurchaseOrders?.orderWeight || 0,
              }}
            />
          </div>

          <div className="d-flex flex-row justify-content-between mb-3 color-gray-400">
            <div className="fs-14 ">Объем</div>
            <FormattedMessage
              defaultMessage="{value} м³"
              id="order.label.m³"
              description="Единица измерения"
              values={{
                value: availablePurchaseOrders?.orderVolume || 0,
              }}
            />
          </div>
          <div className="d-flex flex-row justify-content-between mb-3 color-gray-400">
            <div className="fs-14 ">Плотность</div>
            <FormattedMessage
              defaultMessage="{value} кг/м³"
              id="DeliveryOrderCreate.label.kg/m³"
              description="Единица измерения"
              values={{
                value: availablePurchaseOrders?.orderDensity || 0,
              }}
            />
          </div>
          <hr />

          <div className="d-flex flex-row justify-content-between mb-3 color-gray-400">
            <div className="fs-14 ">Упаковка</div>
            <div>{toMoney(Number(calculation?.package || 0))} $</div>
          </div>

          <div className="d-flex flex-row justify-content-between mb-3">
            <div className="fs-14 color-gray-400">
              Доставка с транзитного склада
            </div>
            {purchaseOrders.length > 0 && calculation ? (
              <div className="">
                + {toMoney(Number(calculation.delivery))} $
              </div>
            ) : (
              <div className="color-gray-400">+ 0 $</div>
            )}
          </div>
          <div className="d-flex flex-row justify-content-between mb-3">
            <div className="fs-14 color-gray-400">Страховой сбор</div>
            {purchaseOrders.length > 0 && calculation ? (
              <div className="">
                + ${toMoney(Number(calculation.insurance))} $
              </div>
            ) : (
              <div className="color-gray-400">+ 0 $</div>
            )}
          </div>
          <div className="d-flex flex-row justify-content-between mb-3">
            <div className="fs-14 color-gray-400">Транспортная компания</div>
            {purchaseOrders.length > 0 && calculation ? (
              <div className="">
                + ${toMoney(Number(calculation.localDelivery))} $
              </div>
            ) : (
              <div className="color-gray-400">+ 0 $</div>
            )}
          </div>
          <hr />
          <div className="d-flex flex-row justify-content-between mb-4">
            <div>Итого к оплате:</div>
            {purchaseOrders.length > 0 && calculation ? (
              <div>{toMoney(Number(calculation.total))} $</div>
            ) : (
              <div>0 $</div>
            )}
          </div>
          <button
            type="submit"
            className="btn btn-success w-100 mb-3  rounded-8"
            disabled={isSubmitting}
          >
            Оформить доставку
            {isSubmitting && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
          <div className="fs-14 color-gray-400 mt-3">
            Указана приблизительная стоимость доставки. Финальная стоимость
            будет рассчитана после обработки заказа менеджером. Не является
            публичной офертой. Учитывайте, тариф доставки считается от 1 кг
          </div>
        </div>
      </div>
    </form>
  );
};

type DeliveryOrderFormProps = {
  onSubmit: (form: DeliveryOrderFormData) => Promise<void>;
  defaultValues?: Partial<DeliveryOrderFormData>;
};

type DeliveryOrderFormData = {
  purchaseOrders: string[];
  packagesGroup: PackageGroup[];
  deliveryMethod: string;
  localDeliveryMethod: string;
  address: string;
  user: User;
};

export const PurchaseOrderItem = ({
  order,
  isLink,
}: {
  order: CleanPurchaseOrder;
  isLink?: boolean;
}) => {
  const navigate = useNavigate();
  const ORDER_CATEGORIES = useOrderCategories();
  return (
    <div className="w-80">
      <div className="fw-bold d-flex">
        {order.type === 'purchase' ? 'V' : 'K'}
        <div
          className="cursor-pointer"
          style={{ width: 'fit-content' }}
          onClick={() => (isLink ? navigate(`/purchases/${order.id}`) : null)}
        >
          {' '}
          {order.internalId}
        </div>
        - {order.name}
      </div>
      <div className="fs-14 color-gray-400">
        {order.category && ORDER_CATEGORIES[order.category]}, {order.weight}кг,{' '}
        {order.volume}м³, {order.price}{' '}
        {getCurrencySymbol(order.currency ?? 'cny')}
        {order.supplier?.name && ` Поставщик - ${order.supplier.name}`}
      </div>
    </div>
  );
};
