import React from 'react';
import Table, { CellProps } from 'rsuite/Table';
import IconButton from 'rsuite/IconButton';
import MyLoader from '../../common/loader.component';
import { RouterOutput, trpcSwr } from '../../../api/trpc';
import { useUtmList } from './useUtmList';
import { ToggleCell } from '../../common/table/toggle-cell';
import { fetcher } from '../../../services/api';
import { AlertService } from '../../../services/alert.service';
import { ModalsService } from '../../../services/modals.service';
import { AddUtm } from './AddUtm';
import { WhisperSpeaker } from '../../../utils/whisper-speaker';
import Popover from 'rsuite/Popover';
import Dropdown from 'rsuite/Dropdown';
import Whisper from 'rsuite/Whisper';

type Item = RouterOutput['utmAdmin']['list']['items'][number];

export const UtmsTab = () => {
  const { data: utmList, isLoading, error, mutate } = useUtmList();
  const { trigger: triggerUpdate } = trpcSwr.utmAdmin.update.useSWRMutation({
    fetcher,
  });
  const { trigger: triggerDelete } = trpcSwr.utmAdmin.delete.useSWRMutation({
    fetcher,
  });

  const renderMenu =
    (item: Item): WhisperSpeaker =>
    ({ onClose, left, top, className }, ref) => {
      const handleSelect = (eventKey: number | string | undefined) => {
        onClose();
        if (eventKey === 'edit') {
          ModalsService.createModal(AddUtm, { item }).then(() => mutate());
        } else if (eventKey === 'delete') {
          triggerDelete({ id: item.id }).then(() => {
            mutate();
          });
        }
      };
      return (
        <Popover ref={ref} className={className} style={{ left, top }} full>
          <Dropdown.Menu onSelect={handleSelect}>
            <Dropdown.Item eventKey={'edit'}>
              <i className="bi bi-pencil" />
              <span className="ps-2">Изменить</span>
            </Dropdown.Item>
            <Dropdown.Item eventKey={'delete'}>
              <i className="bi bi-trash" />
              <span className="ps-2">Удалить</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Popover>
      );
    };

  const ActionCell = ({ rowData, dataKey, ...props }: CellProps<Item>) => {
    return (
      rowData && (
        <Table.Cell
          {...props}
          className="link-group no-padding-cell"
          align="right"
        >
          <Whisper
            placement="leftStart"
            trigger="click"
            speaker={renderMenu(rowData)}
          >
            <IconButton
              appearance="subtle"
              icon={<i className="bi bi-three-dots" />}
            />
          </Whisper>
        </Table.Cell>
      )
    );
  };

  if (isLoading) {
    return (
      <>
        <div className="pb-4">
          <MyLoader />
        </div>
      </>
    );
  }

  if (error || !utmList) {
    return (
      <>
        <div className="pb-4">Неизвестная ошибка</div>
      </>
    );
  }

  return (
    <div>
      <div className="color-gray-600">
        Раздел предназначен для управления заголовками лидов в CRM bitrix24
        после регистрации в системе. В поле <b>utm_referrer=</b> необходимо
        добавлять только его значение. <b>utm_referrer</b> следует использовать
        в ссылке для регистрации, в виде
        <b> my.express-today.ru/registration?utm_referrer=значение</b>
      </div>
      <Table
        loading={isLoading}
        data={utmList.items || []}
        headerHeight={57}
        rowHeight={70}
        autoHeight
        rowKey="id"
      >
        <Table.Column width={400} verticalAlign="middle" fullText>
          <Table.HeaderCell>Название utm</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => {
              return (
                <>
                  utm_referrer={rowData.value}{' '}
                  <div className={'mleft-auto'}>
                    <IconButton
                      circle={true}
                      appearance="subtle"
                      size={'lg'}
                      className="ms-4"
                      icon={<i className="bi bi-link" />}
                      onClick={() => {
                        navigator.clipboard
                          .writeText(`?utm_referrer=${rowData.value}`)
                          .then(() => {
                            AlertService.success();
                          });
                      }}
                    />
                  </div>
                </>
              );
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={250} verticalAlign="middle" fullText>
          <Table.HeaderCell>Заголовок</Table.HeaderCell>
          <Table.Cell>{(rowData) => <>{rowData.title}</>}</Table.Cell>
        </Table.Column>
        <Table.Column width={100} verticalAlign="middle" fullText>
          <Table.HeaderCell>Включена</Table.HeaderCell>
          <ToggleCell
            dataKey="disabled"
            inverted={true}
            onChange={(rowData, checked) => {
              triggerUpdate({
                id: rowData.id,
                data: {
                  disabled: checked,
                },
              }).then(() => {
                mutate();
                AlertService.success();
              });
            }}
          />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell> </Table.HeaderCell>
          <ActionCell />
        </Table.Column>
      </Table>
    </div>
  );
};
