import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { CleanPurchaseOrder } from '../../../export-types/cleaned-types';
import { api, fetcher } from '../../../services/api';
import React, { useEffect, useState } from 'react';
import { IdCell } from '../../common/cards/id-cell-card.component';
import { Gallery } from '../../common/gallery/gallery';
import { getFileV2ApiUrl } from '../../../utils/common.utils';
import PdfIcon from '../../../assets/pdf-file.svg';
import { MediaInput, MediaInputValue } from '../../common/media-input';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useOrderCategoriesList } from '../../../consts/db-values';
import { PurchaseOrderCategory } from '../purchase-order/purchase-order-receipt-confirmation-modal';
import * as Sentry from '@sentry/react';
import { AlertService } from '../../../services/alert.service';
import { decimalValidation, required } from '../../../utils/validations';
import { InputWrapper } from '../../common/input-wrapper';
import { ModalsService } from '../../../services/modals.service';
import { ConfirmModal } from '../../common/confirm-modal';
import '../../../sass/terminal.scss';
import moment from 'moment';
import { usePurchaseOrders } from '../../../api/use-purchase-orders';
import { TerminalPurchaseOrderItem } from './purchase-order-item.component';
import { useStorageLocation } from '../../../api/delivery';
import CreatableSelect from 'react-select/creatable';

type Form = {
  count: string;
  weight: string;
  volume: string;
  productFiles: MediaInputValue[];
  category: PurchaseOrderCategory;
  storageLocation: { value: string; label: string };
};

/** Редактор данных товара в ТСД, также если товар в пути, можно подтвердить его нахождение на складе */
export const TerminalPurchaseOrderEditor = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  // Получить конкретный заказ для редактирования
  const { data: order } = useSWR<CleanPurchaseOrder>(
    `/purchase-order/${id}`,
    fetcher,
  );

  // Получить список заказов с определенными статусами
  const { data: similarOrders } = usePurchaseOrders({
    internalId: order?.user?.internalId?.toString(), // ID, по которому искать заказы
    status: ['onTheWayToStock', 'receivedInStock', 'receivedInRetailStock'],
  });

  const { data: storageLocations } = useStorageLocation();

  const { register, formState, handleSubmit, watch, control } = useForm<Form>({
    defaultValues: {
      productFiles: order?.productFilesV2.map((file) => ({
        type: 'exist',
        file,
      })),
      storageLocation: {
        value: order?.storageLocation || '',
        label: order?.storageLocation || '',
      },
    },
  });
  const intl = useIntl();
  const ORDER_CATEGORIES_LIST = useOrderCategoriesList();

  const [btnSubmitDisabled, setBtnSubmitDisabled] = useState<boolean>(false); // Отключать кнопку при отправке
  const productFiles = watch('productFiles');
  const [updateFilesResponse, setUpdateFilesResponse] = useState<
    MediaInputValue[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputStorage, setInputStorage] = useState(order?.storageLocation);

  const onSubmit = handleSubmit(async (data) => {
    // Собрать тело запроса для отправки на бекенд
    const body = {
      count: Number(data.count),
      weight: data.weight,
      volume: data.volume,
      category: data.category,
      productFiles: updateFilesResponse
        .filter(
          (item): item is typeof item & { type: 'exist' } =>
            item.type === 'exist',
        )
        .map((response) => ({ id: response.file.id })),
      storageLocation: data.storageLocation.value,
      processingFromTerminal: true,
    };

    // Отправить запрос и поймат ошибку
    try {
      if (!order || !order.id) return;

      // Если товар в пути, то при нажатии подтвержить его нахождение на складе
      if (order.status === 'onTheWayToStock')
        await api.post(
          `/purchase-order/v2/${order.id}/receipt-confirmation`,
          body,
        );
      // Eсли товар на складе - чтобы добавить новые фото в получение на складе
      else if (order.status === 'receivedInStock') {
        await api.put(
          `/purchase-order/v2/${order.id}/receipt-confirmation`,
          body,
        );
        // В ином случае просто обновить метаданные товара без изменения статуса
      } else await api.put(`/purchase-order/metadata/${order.id}`, body);
    } catch (e) {
      Sentry.captureException(e, {
        extra: { debug: 'PurchaseOrderReceiptConfirmationModal: api', body },
      });
      return;
    }

    // Сообщить об успешном обновлеии и направить пользователя обратно в список
    AlertService.success();
    navigate('/terminal/search');
  });

  function back() {
    if (!order) return;

    ModalsService.createModal(ConfirmModal, {
      title: intl.formatMessage({
        defaultMessage: 'Закончить приёмку?',
        id: 'terminal.cta.finishAcceptance',
        description: 'Кнопка',
      }),
      buttonText: intl.formatMessage({
        defaultMessage: 'Да',
        id: 'terminal.button.yes',
        description: 'Кнопка',
      }),
    }).then(() => navigate(`/terminal/purchases/${order.id}`));
  }

  useEffect(() => {
    if (productFiles === undefined) {
      setBtnSubmitDisabled(false);
      return;
    }

    const processFiles = async () => {
      let filesResponse = [];

      for (const file of productFiles) {
        setBtnSubmitDisabled(true);

        if (file?.type === 'new' && file.uploading) {
          setLoading(true);

          try {
            const response = await file.uploading;
            if (response.file.id) {
              filesResponse.push({
                type: 'exist' as const,
                file: response.file,
              });
            } else {
              filesResponse.push({
                type: file.type,
                file: file.file,
              });
            }
          } catch (e) {
            filesResponse.push({
              type: file.type,
              file: file.file,
            });
            Sentry.captureException(e, {
              extra: { debug: 'PurchaseOrderReceiptConfirmationModal: файлы' },
            });
          }
        } else if (file?.type === 'exist') {
          filesResponse.push({
            type: file.type,
            file: file.file,
          });
        }
        setLoading(false);
      }

      setUpdateFilesResponse(filesResponse);

      const hasMissingId = filesResponse.some(
        (file) => file.type !== 'exist' || !file.file || !file.file.id,
      );

      if (hasMissingId) setBtnSubmitDisabled(true);
      else setBtnSubmitDisabled(false);
    };

    processFiles();
  }, [productFiles]);

  if (!order) return null;

  // Получить дату принятия на склад
  const date = moment(order.receiptDate).format('DD.MM.YYYY');

  return (
    <div className="d-flex flex-column gap-3">
      {/* Форма для обновления заказа */}
      <form
        onSubmit={onSubmit}
        className="d-flex flex-column justify-content-around m-3 gap-4"
      >
        {/** Заголовок компонента, название, базовая информация */}
        <div className="d-flex">
          <Gallery className="overflow-x-auto d-flex justify-content-center gap-2">
            {order.requestFilesV2.map((file, index) => (
              <a
                href={getFileV2ApiUrl(file.id)}
                key={index}
                data-iframe={file.type === 'pdf'}
              >
                <img
                  className="rounded fit-cover me-2"
                  src={
                    file.type === 'pdf'
                      ? PdfIcon
                      : getFileV2ApiUrl(file.id, '64x64')
                  }
                  width={101}
                  height={89}
                  alt=""
                />
              </a>
            ))}
          </Gallery>

          {/* Контейнер с доп.ифнормацией о заказе */}
          <div style={{ fontSize: '1.5rem' }}>
            <IdCell internalId={order.internalId} type={order.type} />
            <div
              className="d-flex flex-row gap-2"
              style={{ fontSize: '0.75rem' }}
            >
              {/* Код владельца заказа */}
              {order.user && (
                <div className="d-flex gap-1 align-items-center">
                  <i className="bi bi-person-fill text-success" />
                  {order.user.internalId}
                </div>
              )}

              {/* Статус заказа */}
              <div className="d-flex gap-1 align-items-center">
                <i className="bi bi-circle text-success" />
                {order.status === 'onTheWayToStock' ? (
                  <FormattedMessage
                    defaultMessage="В пути"
                    id="terminal.value.onWay"
                    description="Значение Пункта"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="На складе"
                    id="terminal.value.inStorage"
                    description="Значение Пункта"
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Контейнер с доп.информацией о проекте */}
        <div className="d-flex flex-column gap-1">
          {/* Время принятия на склад */}
          {order.receiptDate && order.status !== 'onTheWayToStock' && (
            <div className="d-flex gap-2 align-items-center">
              <i className="bi bi-calendar text-success" />{' '}
              <FormattedMessage
                defaultMessage="Приятно на складе"
                id="terminal.label.accaptedInStorage"
                description="Названия Пункта"
              />
              <hr className="vr m-0" />
              {date}
            </div>
          )}

          {/* Место хранения заказа на складе */}
          {order.storageLocation && order.status !== 'onTheWayToStock' && (
            <div className="d-flex gap-2 align-items-center">
              <i className="bi bi-archive-fill text-success" />{' '}
              <FormattedMessage
                defaultMessage="Место хранения"
                id="terminal.label.storageLocation"
                description="Информация о месте хранения"
              />
              <hr className="vr m-0" />
              {order.storageLocation}
            </div>
          )}

          {/* Транспортная компания, которая перевозит товар */}
          {order.transportCompany && order.status === 'onTheWayToStock' && (
            <div className="d-flex gap-2 align-items-center">
              <i className="bi bi-truck text-success" />{' '}
              <FormattedMessage
                defaultMessage="Перевозчик"
                id="terminal.label.transportCompany"
                description="Названия Пункта"
              />
              <hr className="vr m-0" />
              {order.transportCompany}
            </div>
          )}

          {/* Номер договора ТТН */}
          {order.landingBill && order.status === 'onTheWayToStock' && (
            <div className="d-flex gap-2 align-items-center">
              <i className="bi bi-file-text-fill text-success" /> ТТН
              <hr className="vr m-0" />
              {order.landingBill}
            </div>
          )}
        </div>

        {/* Форма для редактирования заказа */}
        <div>
          <InputWrapper
            theme="light"
            title={intl.formatMessage({
              defaultMessage: 'Кол-во товара',
              id: 'input.label.quantityOfGoods',
              description: 'Заголовок поля ввода',
            })}
            error={formState.errors.count?.message}
            required
          >
            <div className="input-group">
              <input
                className="form-control"
                type="number"
                defaultValue={order.count?.toString()}
                min={1}
                step={1}
                {...register('count', {
                  ...required,
                  ...decimalValidation,
                })}
              />
              <span className="input-group-text">
                <FormattedMessage
                  defaultMessage="шт"
                  id="input.label.pcs"
                  description="Единица измерения"
                />
              </span>
            </div>
          </InputWrapper>

          <div className="mt-3">
            <div className="row align-items-center">
              <div className="col-6">
                <InputWrapper
                  theme="light"
                  title={intl.formatMessage({
                    defaultMessage: 'Вес',
                    id: 'input.label.weight',
                    description: 'Заголовок поля ввода',
                  })}
                  error={formState.errors.weight?.message}
                  required
                >
                  <div className="input-group">
                    <input
                      className="form-control"
                      type="number"
                      defaultValue={order.weight?.toString()}
                      min={0}
                      step={0.01}
                      {...register('weight', {
                        ...required,
                        ...decimalValidation,
                      })}
                    />
                    <span className="input-group-text">
                      <FormattedMessage
                        defaultMessage="кг"
                        id="input.label.kg"
                        description="Единица измерения"
                      />
                    </span>
                  </div>
                </InputWrapper>
              </div>

              <div className="col-6">
                <InputWrapper
                  theme="light"
                  title={intl.formatMessage({
                    defaultMessage: 'Объём',
                    id: 'input.label.volume',
                    description: 'Заголовок поля ввода',
                  })}
                  error={formState.errors.volume?.message}
                  required
                >
                  <div className="input-group">
                    <input
                      className="form-control"
                      type="number"
                      defaultValue={order.volume?.toString()}
                      min={0}
                      step={0.01}
                      {...register('volume', {
                        ...required,
                        ...decimalValidation,
                      })}
                    />
                    <span className="input-group-text">
                      <FormattedMessage
                        defaultMessage="м³"
                        id="input.label.length"
                        description="Единица измерения"
                      />
                    </span>
                  </div>
                </InputWrapper>
              </div>
            </div>
          </div>

          <InputWrapper
            theme="light"
            className="mt-3"
            title={intl.formatMessage({
              defaultMessage: 'Категория товара',
              id: 'input.input-label.goodsCategory',
              description: 'Заголовок поля ввода',
            })}
            error={formState.errors.category?.message}
            required
          >
            <select
              defaultValue={order.category || undefined}
              className="form-select"
              {...register('category', { ...required })}
            >
              <option value="" hidden>
                <FormattedMessage
                  defaultMessage="Выберите категорию товара"
                  id="purchaseOrderModal.placeholder-label.selectAGoodsCategory"
                  description="Заголовок выпадающего меню"
                />
              </option>
              {ORDER_CATEGORIES_LIST.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </InputWrapper>

          <InputWrapper
            theme="light"
            className="mt-3"
            title={intl.formatMessage({
              defaultMessage: 'Место хранения',
              id: 'terminal.label.storageLocation',
              description: 'Информация о месте хранения',
            })}
            error={formState.errors.storageLocation?.message}
            required
          >
            <Controller
              name="storageLocation"
              control={control}
              rules={{
                ...required,
                maxLength: {
                  value: 20,
                  message: 'Максимальная длина 20 символов',
                },
              }}
              render={({ field }) => (
                <CreatableSelect
                  {...field}
                  options={storageLocations?.map((location) => ({
                    value: location,
                    label: location,
                  }))}
                  placeholder=""
                  formatCreateLabel={(value) => `Добавить "${value}"`}
                  onInputChange={(newValue) => {
                    setInputStorage(newValue);
                  }}
                  onChange={(newValue) => {
                    setInputStorage('');
                    field.onChange(newValue);
                  }}
                  onBlur={() => {
                    if (inputStorage) {
                      field.onChange({
                        value: inputStorage,
                        label: inputStorage,
                      });
                    }
                  }}
                />
              )}
            />
          </InputWrapper>

          <Controller
            control={control}
            name="productFiles"
            rules={{ ...required }}
            render={({ field, fieldState }) => (
              <InputWrapper
                theme="light"
                title={intl.formatMessage({
                  defaultMessage: 'Фотографии товаров при получении',
                  id: 'input.label.photosOfGoodsOnReceipt',
                  description: 'Заголовок поля ввода',
                })}
                className="mt-3"
                error={fieldState.error?.message}
                required
              >
                <MediaInput
                  {...field}
                  multiple
                  enableUploadOnAttach
                  loading={loading}
                  setLoading={setLoading}
                  updateFilesResponse={updateFilesResponse}
                />
              </InputWrapper>
            )}
          />
        </div>

        {/* Контейнер с кнопками для заказа */}
        <div className="w-100 d-flex flex-column gap-2">
          <button
            className="btn btn-success"
            type="submit"
            disabled={formState.isSubmitting || btnSubmitDisabled}
          >
            <FormattedMessage
              defaultMessage="Подтвердить"
              id="terminal.button.confirm"
              description="Кнопка"
            />
          </button>
          <button
            className="btn btn-outline-secondary"
            onClick={back}
            disabled={formState.isSubmitting}
          >
            <FormattedMessage
              defaultMessage="Назад"
              id="terminal.button.back"
              description="Кнопка"
            />
          </button>
        </div>
      </form>

      {/* Список заказов от того же заказчика */}
      {similarOrders &&
        similarOrders.items
          .filter((i) => order?.internalId !== i.internalId)
          .filter((i) => i.status !== 'onTheWayToStock').length > 0 && (
          <section className="m-3">
            {/* Заголовок списка */}
            <p className="color-gray-400">
              <FormattedMessage
                defaultMessage="Другие заказы клиента на складе"
                id="terminal.title.otherOrdersOfClientInStorage"
                description="Заголовок Списка"
              />
            </p>

            {/* Список заказов */}
            <div className="d-flex flex-column gap-3">
              {similarOrders?.items
                // Убрать из списка редактируемый заказ
                .filter((i) => order?.internalId !== i.internalId)
                .filter((i) => i.status !== 'onTheWayToStock')
                .map((i) => {
                  return (
                    <TerminalPurchaseOrderItem
                      order={i}
                      key={i.id}
                      type="metadata"
                    />
                  );
                })}
            </div>
          </section>
        )}
    </div>
  );
};
