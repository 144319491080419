import { useState } from 'react';

export const ZoomImage: React.FC<{
  src: string;
  zoomSrc: string;
  width: number;
  height: number;
  className?: string;
}> = ({ src, width, height, className, zoomSrc }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [position, setPosition] = useState({ x: '50%', y: '50%' });
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    const { left, top, width, height } =
      e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;
    setPosition({ x: `${x}%`, y: `${y}%` });
  };

  const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const img = e.currentTarget;
    setImageDimensions({
      width: img.naturalWidth,
      height: img.naturalHeight,
    });
  };

  const aspectRatio = imageDimensions.width / imageDimensions.height;

  const containerWidth = 400;
  const containerHeight = containerWidth / aspectRatio;

  return (
    <div
      style={{ width, height, position: 'relative' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered ? (
        <div
          className={`position-relative overflow-hidden ${className}`}
          onMouseMove={handleMouseMove}
          style={{
            width: `${containerWidth}px`,
            height: `${containerHeight}px`,
            zIndex: 50,
          }}
        >
          <img
            src={zoomSrc}
            alt={''}
            className={`transition w-100 h-100 ${
              isHovered ? 'scale-150' : 'scale-100'
            } `}
            style={{
              transformOrigin: `${position.x} ${position.y}`,
              objectFit: 'cover',
            }}
            onLoad={handleImageLoad}
          />
        </div>
      ) : (
        <img
          className={className}
          src={src}
          width={width}
          height={height}
          alt=""
        />
      )}
    </div>
  );
};
