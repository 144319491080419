type Props = {
  className?: string;
};

export const IconAnalytic = ({ className }: Props) => {
  return (
    <svg className={className} width="17" height="17" viewBox="0 0 490 490">
      <g>
        <g>
          <g>
            <polygon
              points="326.1,24.9 326.1,102.7 399.6,102.7 "
              fill="#000000"
              style={{ fill: 'rgb(71, 84, 103)' }}
            ></polygon>
            <path
              d="M316,122.5c-5.4,0-9.7-4.3-9.7-9.7V0l0,0H67.1v490h355.8V122.5H316z M211,52.9c35,0,63.4,28.4,63.4,63.4H211V52.9z M181.8,81.7v63.4h63.4c0,35-28.4,63.4-63.4,63.4s-63.4-28.4-63.4-63.4C118.4,110.4,146.8,81.7,181.8,81.7z M362.2,435.9H137.9 c-5.4,0-9.7-4.3-9.7-9.7c0-5.4,4.3-9.7,9.7-9.7h224.4c5.4,0,9.7,4.3,9.7,9.7C372,431.7,367.7,435.9,362.2,435.9z M362.2,358.9 H137.9c-5.4,0-9.7-4.3-9.7-9.7s4.3-9.7,9.7-9.7h224.4c5.4,0,9.7,4.3,9.7,9.7C372,354.7,367.7,358.9,362.2,358.9z M362.2,281.9 H137.9c-5.4,0-9.7-4.3-9.7-9.7s4.3-9.7,9.7-9.7h224.4c5.4,0,9.7,4.3,9.7,9.7C372,277.3,367.7,281.9,362.2,281.9z"
              fill="#000000"
              style={{ fill: 'rgb(71, 84, 103)' }}
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
