import React, { useEffect, useState } from 'react';
import {
  NavLink,
  Outlet,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import useSWR from 'swr';
import { fetcher } from '../../../services/api';
import { RbacObject } from '../../../export-types/cleaned-types';
import { Rbac } from '../../common/Rbac';
import { FormattedMessage, useIntl } from 'react-intl';
import { SupplierSelect, useSelectSupplierQs } from './supplier-select';
import { RbacType } from '../finance/finance-admin-screen.component';
import Toggle from 'rsuite/Toggle';
import { useSearchParamsData } from './useSearchParamsData';
import { useProfile } from '../../../api/profile';
import { usePurchaseOrders } from '../../../api/use-purchase-orders';
import { PurchaseOrdersTotalParameters } from './purchase-order-total-parameters';

export type Tab<Object extends RbacObject | undefined = undefined> = {
  title: string;
  id?: string;
  path: string;
  name: string;
  rbac?: Object extends RbacObject ? RbacType<Object> : undefined;
};

export type OutletContextParams = {
  debouncedInternalId: string;
  direction: string;
  linked: boolean;
  selectSupplierId: number | undefined;
  overdue: boolean;
};

const useTabs = () => {
  const intl = useIntl();
  const WFP: Tab<RbacObject.Purchase> = {
    title: intl.formatMessage({
      id: 'orders.tab-label.awaitingPayment',
      defaultMessage: 'Ожидают оплаты',
      description: 'Надпись на вкладке',
    }),
    path: '/purchases/orders/waiting-for-payment',
    name: 'waitingForPayment',
    rbac: { object: RbacObject.Purchase, action: 'read:extended-statuses' },
  };
  const HF: Tab<RbacObject.Purchase> = {
    title: 'Скрытые',
    path: '/purchases/orders/hidden-frozen',
    name: 'hiddenFrozen',
    rbac: { object: RbacObject.Purchase, action: 'read:hiddenPurchase' },
  };

  return [
    WFP,
    {
      title: intl.formatMessage({
        id: 'orders.tab-label.redeem',
        defaultMessage: 'Выкупить',
        description: 'Надпись на вкладке',
      }),
      path: '/purchases/orders/in-work',
      name: 'inWork',
    },
    {
      title: intl.formatMessage({
        id: 'orders.tab-label.frozen',
        defaultMessage: 'Заморожен',
        description: 'Надпись на вкладке',
      }),
      path: '/purchases/orders/frozen',
      name: 'frozen',
    },
    HF,
    {
      title: intl.formatMessage({
        id: 'orders.tab-label.inTransit',
        defaultMessage: 'В пути',
        description: 'Надпись на вкладке',
      }),
      path: '/purchases/orders/on-the-way',
      name: 'onTheWayToStock',
    },
    {
      title: intl.formatMessage({
        id: 'tabs.lable.inStock',
        defaultMessage: 'На складе',
        description: 'Надпись на вкладке',
      }),
      path: '/purchases/orders/received-in-stock',
      name: 'receivedInStock',
    },
    {
      title: intl.formatMessage({
        id: 'orders.tab-label.receivedInRetailStock',
        defaultMessage: 'На складе (Розница)',
        description: 'Значение Пункта/Надпись на вкладке',
      }),
      path: '/purchases/orders/received-in-retail-stock',
      name: 'receivedInRetailStock',
    },
    {
      title: intl.formatMessage({
        id: 'tabs.lable.archive',
        defaultMessage: 'Архив',
        description: 'Надпись на вкладке',
      }),
      path: '/purchases/orders/archive',
      name: 'archive',
    },
  ];
};

export const AdminPurchaseOrdersTabs: React.FC = () => {
  const intl = useIntl();
  const tabs = useTabs();
  const { data: user } = useProfile();

  const [searchParams] = useSearchParams();

  const [searchParamsData, setSearchParamsFunctions] = useSearchParamsData();

  const idClient = searchParams.get('idclient');
  const [direction, setDirection] = useState('asc');
  const [linked, setLinked] = useState(false);
  const [overdue, setOverdue] = useState(false);
  const [supplierId] = useSelectSupplierQs();
  const [internalId, setInternalId] = useState(
    searchParamsData['internal'] || idClient || '',
  );

  const [skipLoading, setSkipLoading] = useState(true);
  useEffect(() => {
    setSkipLoading(!!user?.accesses.includes('fulfillment'));
  }, [user]);

  useEffect(() => {
    if (searchParamsData['internal'] !== internalId) {
      setSearchParamsFunctions['internal'](internalId);
    }
  }, [internalId, setSearchParamsFunctions]);

  const linkedChange = () => {
    setLinked(!linked);
  };
  const overdueChanged = () => {
    setOverdue(!overdue);
  };

  const sortHandleChange = () => {
    setDirection(direction === 'asc' ? 'desc' : 'asc');
  };

  const { data, isLoading } = useSWR(
    {
      url: '/purchase-order/total-by-status',
      params: {
        internalId: internalId ? internalId : undefined,
        linked,
        overdue,
        supplierId,
      },
    },
    fetcher,
  );

  const ordersCount = React.useMemo(() => {
    if (!data) {
      return {};
    }

    return {
      waitingForPayment: data.items.waitingForPayment || 0,
      inWork: data.items.inWork || 0,
      frozen: data.items.frozen || 0,
      hiddenFrozen: data.items.hiddenFrozen || 0,
      onTheWayToStock: data.items.onTheWayToStock || 0,
      receivedInStock: data.items.receivedInStock || 0,
      receivedInRetailStock: data.items.receivedInRetailStock || 0,
      archive: (data.items.sentToRussia || 0) + (data.items.canceled || 0),
    };
  }, [data]);

  function renderTabCount(item: Tab) {
    if (isLoading) {
      return (
        <>
          (<i className="bi bi-three-dots color-gray-400" />)
        </>
      );
    }

    return `(${ordersCount[item.name as keyof typeof ordersCount]})`;
  }

  const { pathname } = useLocation();
  const nameTab = pathname.split('/')[3];

  const context: OutletContextParams = {
    debouncedInternalId: internalId,
    direction,
    linked,
    selectSupplierId: supplierId,
    overdue,
  };
  return (
    <>
      <div className="d-flex flex-wrap  justify-content-between align-items-center pb-4">
        <div className="d-flex mr-auto" style={{ width: '65%' }}>
          <input
            className="form-control"
            style={{ width: '13%' }}
            type="text"
            placeholder={intl.formatMessage({
              defaultMessage: 'Поиск',
              id: 'input.placeholder.searchByOrder/CustomerNumber',
              description: 'Поле ввода',
            })}
            value={internalId}
            onChange={(event) => {
              setInternalId(event.currentTarget.value);
            }}
          />
          <Rbac object={RbacObject.Supplier} action={'read:admin'}>
            {!user?.accesses.includes('fulfillment') && (
              <SupplierSelect skipLoading={skipLoading} />
            )}
          </Rbac>
          {nameTab === 'received-in-stock' ? (
            <PurchaseOrdersTotalParameters
              linked={linked}
              overdue={overdue}
              status={['receivedInStock']}
              internalId={internalId}
              supplierId={supplierId}
            />
          ) : null}
        </div>
        <div className="d-flex">
          <Toggle checked={linked} onChange={linkedChange} />
          <div className="ms-2">
            <FormattedMessage
              defaultMessage="Мои клиенты"
              id="switchButton.label.myCustomers"
              description="Надпись на переключателе"
            />
          </div>
        </div>
        <div className="d-flex">
          <Toggle checked={direction === 'desc'} onChange={sortHandleChange} />
          <div className="ms-2">
            <FormattedMessage
              defaultMessage="Сначала новые"
              id="switchButton.label.newOnesFirst"
              description="Надпись на переключателе"
            />
          </div>
        </div>
        <div className="d-flex">
          <Toggle checked={overdue} onChange={overdueChanged} />
          <div className="ms-2">
            <FormattedMessage
              defaultMessage="Заказы с перерасходом"
              id="switchButton.label.overdue"
              description="Надпись на переключателе"
            />
          </div>
        </div>
      </div>

      <ul className="nav nav-pills mb-4 d-inline-flex align-self-start">
        {tabs.map(({ rbac, ...item }) => {
          const tab = (
            <li className="nav-item" key={item.title}>
              <NavLink to={item.path} className="nav-link text-decoration-none">
                {item.title} {renderTabCount(item)}
              </NavLink>
            </li>
          );

          if (rbac) {
            return (
              <Rbac key={item.title} object={rbac.object} action={rbac.action}>
                {tab}
              </Rbac>
            );
          }
          return tab;
        })}
      </ul>
      <Outlet context={context} />
    </>
  );
};
