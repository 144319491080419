import React from 'react';
import Table, { CellProps } from 'rsuite/Table';
import useSWR from 'swr';
import { api, fetcher } from '../../../services/api';
import Toggle from 'rsuite/Toggle';
import { getTopUpMethodTitle, toMoney } from '../../../utils/common.utils';
import Whisper from 'rsuite/Whisper';
import IconButton from 'rsuite/IconButton';
import Dropdown from 'rsuite/Dropdown';
import Popover from 'rsuite/Popover';
import { ModalsService } from '../../../services/modals.service';
import { AddBankCardModel } from './add-bank-card-modal';
import { AlertService } from '../../../services/alert.service';
import { ChangeLimitBankCardModel } from './change-limit-bank-card-modal';
import { AlipayMethodModal } from './alipay-method-modal';
import { WeChatMethodModal } from './we-chat-method-modal';
import {
  AlipayAccount,
  CardToCardAccount,
  WeChatAccount,
  WeChatAdminAccount,
} from './top-up-dto';
import { ChangeCurrenciesBankCardModel } from './change-currencies-bank-card-modal';
import { ChangeCommissionModal } from './change-commission-modal';
import { Rbac } from '../../common/Rbac';
import { CleanFileDto, RbacObject } from '../../../export-types/cleaned-types';
import { useForm } from 'react-hook-form';
import {
  AddInvoiceAccountModel,
  vatValueNames,
} from './add-invoice-account-modal';
import { ChangePaymentNameModal } from './change-payment-name-modal';
import {
  SbpAccountActionCell,
  SbpAccountRow,
  SbpMethodActionCell,
  SbpRow,
} from './sbp-method';

const NameCell: React.FC<CellProps<Row | InvoiceAccountRow>> = function ({
  rowData,
  ...props
}) {
  if (!rowData) return null;
  if (rowData.type === 'account' && rowData.method === 'invoice') {
    return (
      <Table.Cell {...props}>
        {
          vatValueNames[
            (rowData as InvoiceAccountRow)
              ?.vatValue as keyof typeof vatValueNames
          ]
        }
      </Table.Cell>
    );
  }
  if (rowData.type !== 'method') return <Table.Cell {...props} />;
  const name = getTopUpMethodTitle(rowData.method) || rowData.method;

  return <Table.Cell {...props}>{name}</Table.Cell>;
};

const LimitCell: React.FC<CellProps<Row | CardToCardAccountRow>> = function ({
  rowData,
  ...props
}) {
  if (
    rowData &&
    rowData.type === 'account' &&
    ['sberbank', 'alfabank', 'tinkoff'].includes(rowData.method)
  ) {
    return (
      <Table.Cell {...props}>
        {toMoney(rowData.remainingLimit)} / {toMoney(rowData.limit)} ₽
      </Table.Cell>
    );
  }
};

const FeeCell: React.FC<CellProps<Row>> = function ({ rowData, ...props }) {
  if (!rowData) return null;
  if (typeof rowData.fee !== 'number') return <Table.Cell {...props} />;

  return <Table.Cell {...props}>{rowData.fee} %</Table.Cell>;
};

const getCurrencyByMethod = (method: string) => {
  switch (method) {
    case 'sberbank':
    case 'alfabank':
    case 'tinkoff':
    case 'invoice':
    case 'invoice_customs':
    case 'pay-keeper_sbp':
    case 'pay-keeper_card':
    case 'payselection':
    case 'sbp':
    case 'wata':
    case 'wata-sbp':
      return 'RUB';
    case 'alipay':
    case 'wechat':
      return 'CNY';
    case 'cash':
    case 'cash-company': {
      return 'RUB / CNY / USD';
    }
    case 'cryptomus':
    case 'cryptomus-wallet':
    case 'cryptocloud':
      return 'USDT';
  }
};

const CurrenciesCell: React.FC<CellProps<Row>> = function ({
  rowData,
  ...props
}) {
  if (!rowData) return null;
  if (rowData.type !== 'method') return <Table.Cell {...props} />;

  return (
    <Table.Cell {...props}>{getCurrencyByMethod(rowData.method)}</Table.Cell>
  );
};

const TargetCurrenciesCell: React.FC<CellProps<Row | CardToCardAccountRow>> =
  function ({ rowData, ...props }) {
    if (!rowData || !rowData.hasOwnProperty('currencies'))
      return <Table.Cell {...props} />;

    return (
      <Table.Cell {...props}>
        {(rowData as Row & { currencies: Array<string> }).currencies
          .map((t) => t.toUpperCase())
          .join(' / ')}
      </Table.Cell>
    );
  };

type OnChangeDisableCell = (
  data:
    | { type: 'method'; method: string }
    | { type: 'account'; method: string; id: string },
  disabled: boolean,
) => Promise<void>;

const DisableCell: React.FC<
  CellProps<Row> & { onChange: OnChangeDisableCell }
> = ({ rowData, onChange, ...props }) => {
  const [loading, setLoading] = React.useState(false);
  if (!rowData) return null;

  return (
    rowData && (
      <Rbac object={RbacObject.TopUpMethod} action={'write:admin'}>
        <Table.Cell {...props}>
          <Toggle
            checked={!rowData.disabled}
            loading={loading}
            onChange={async (val) => {
              setLoading(true);
              if (rowData.type === 'method') {
                await onChange(
                  { type: 'method', method: rowData.method },
                  !val,
                );
              } else if (rowData.method) {
                await onChange(
                  { type: 'account', id: rowData.id, method: rowData.method },
                  !val,
                );
              }
              setLoading(false);
            }}
          />
        </Table.Cell>
      </Rbac>
    )
  );
};

const BankMethodActionCell: React.FC<
  CellProps<Row | CardToCardAccountRow | InvoiceAccountRow | SbpAccountRow> & {
    onChange: () => void;
  }
> = ({ rowData, onChange, ...props }) => {
  if (!rowData) return null;
  const speaker = (
    { onClose, left, top, className }: any,
    ref: React.Ref<any>,
  ) => (
    <Popover ref={ref} className={className} style={{ left, top }} full>
      <Dropdown.Menu>
        <Dropdown.Item
          onSelect={function () {
            onClose();
            ModalsService.createModal(AddBankCardModel, {
              method: (rowData as CardToCardAccountRow).method,
            })
              .then(() => {
                AlertService.success();
              })
              .then(() => {
                onChange();
              });
          }}
        >
          <i className="bi bi-three-dots" />
          <span className="ps-2">Добавить карту</span>
        </Dropdown.Item>
        <Dropdown.Item
          onSelect={function () {
            console.log({ rowData });
            ModalsService.createModal(ChangeCommissionModal, {
              method: rowData.method,
              fee: (rowData as Row).fee!,
            })
              .then(() => {
                AlertService.success();
              })
              .then(() => {
                onChange();
              });
          }}
        >
          <i className="bi bi-percent" />
          <span className="ps-2">Обновить комиссию</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Popover>
  );

  return (
    <Table.Cell
      {...props}
      className="link-group no-padding-cell pt-2"
      align="right"
    >
      <Whisper placement="leftStart" trigger="click" speaker={speaker}>
        <IconButton
          appearance="subtle"
          icon={<i className="bi bi-three-dots" />}
        />
      </Whisper>
    </Table.Cell>
  );
};

const BankAccountActionCell: React.FC<
  CellProps<CardToCardAccountRow> & { onChange: () => void }
> = ({ rowData, onChange, ...props }) => {
  if (!rowData) return null;
  const speaker = (
    { onClose, left, top, className }: any,
    ref: React.Ref<any>,
  ) => (
    <Popover ref={ref} className={className} style={{ left, top }} full>
      <Dropdown.Menu>
        <Dropdown.Item
          onSelect={function () {
            onClose();
            ModalsService.createModal(ChangeLimitBankCardModel, {
              account: rowData,
            })
              .then(() => {
                AlertService.success();
              })
              .then(() => {
                onChange();
              });
          }}
        >
          <i className="bi bi-arrow-repeat" />
          <span className="ps-2">Обновить лимит</span>
        </Dropdown.Item>
        <Dropdown.Item
          onSelect={function () {
            onClose();
            ModalsService.createModal(ChangeCurrenciesBankCardModel, {
              account: rowData,
            })
              .then(() => {
                AlertService.success();
              })
              .then(() => {
                onChange();
              });
          }}
        >
          <i className="bi bi-currency-exchange" />
          <span className="ps-2">Изменить разреш. счета</span>
        </Dropdown.Item>
        <Dropdown.Item
          onSelect={function () {
            onClose();
            api
              .delete(
                `/top-up/methods/${rowData.method}/accounts/${rowData.id}`,
              )
              .then(() => {
                AlertService.success();
              })
              .then(() => {
                onChange();
              });
          }}
        >
          <i className="bi bi-trash" />
          <span className="ps-2">Удалить</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Popover>
  );

  return (
    <Table.Cell
      {...props}
      className="link-group no-padding-cell pt-2"
      align="right"
    >
      <Whisper placement="leftStart" trigger="click" speaker={speaker}>
        <IconButton
          appearance="subtle"
          icon={<i className="bi bi-three-dots" />}
        />
      </Whisper>
    </Table.Cell>
  );
};

const GenericActionCell: React.FC<
  CellProps<Row> & { onChange: () => void }
> = ({ rowData, onChange, ...props }) => {
  if (!rowData) return null;
  const speaker = (
    { onClose, left, top, className }: any,
    ref: React.Ref<any>,
  ) => (
    <Popover ref={ref} className={className} style={{ left, top }} full>
      <Dropdown.Menu>
        <Dropdown.Item
          onSelect={function () {
            onClose();
            ModalsService.createModal(ChangeCommissionModal, {
              method: rowData.method,
              fee: rowData.fee!,
            })
              .then(() => {
                AlertService.success();
              })
              .then(() => {
                onChange();
              });
          }}
        >
          <i className="bi bi-percent" />
          <span className="ps-2">Обновить комиссию</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Popover>
  );

  return (
    <Table.Cell
      {...props}
      className="link-group no-padding-cell pt-2"
      align="right"
    >
      <Whisper placement="leftStart" trigger="click" speaker={speaker}>
        <IconButton
          appearance="subtle"
          icon={<i className="bi bi-three-dots" />}
        />
      </Whisper>
    </Table.Cell>
  );
};

const InvoiceMethodActionCell: React.FC<
  CellProps<Row | InvoiceRow> & { onChange: () => void }
> = ({ rowData, onChange, ...props }) => {
  if (!rowData) return null;
  const speaker = (
    { onClose, left, top, className }: any,
    ref: React.Ref<any>,
  ) => (
    <Popover ref={ref} className={className} style={{ left, top }} full>
      <Dropdown.Menu>
        <Dropdown.Item
          onSelect={function () {
            onClose();
            ModalsService.createModal(AddInvoiceAccountModel, {
              onSubmit: async (result) => {
                await api.post(`/top-up/methods/invoice/accounts`, result);
              },
              buttonTitle: 'Добавить',
            })
              .then(() => {
                AlertService.success();
              })
              .then(() => {
                onChange();
              });
          }}
        >
          <i className="bi bi-three-dots" />
          <span className="ps-2">Добавить юр. лицо</span>
        </Dropdown.Item>
        <Dropdown.Item
          onSelect={function () {
            ModalsService.createModal(ChangeCommissionModal, {
              method: rowData.method,
              fee: (rowData as Row).fee!,
            })
              .then(() => {
                AlertService.success();
              })
              .then(() => {
                onChange();
              });
          }}
        >
          <i className="bi bi-percent" />
          <span className="ps-2">Обновить комиссию</span>
        </Dropdown.Item>
        <Dropdown.Item
          onSelect={function () {
            ModalsService.createModal(ChangePaymentNameModal, {
              method: rowData.method,
              paymentName: (rowData as InvoiceRow).paymentName!,
            })
              .then(() => {
                AlertService.success();
              })
              .then(() => {
                onChange();
              });
          }}
        >
          <i className="bi bi-paypal" />
          <span className="ps-2">Обновить наименование платежа</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Popover>
  );

  return (
    <Table.Cell
      {...props}
      className="link-group no-padding-cell pt-2"
      align="right"
    >
      <Whisper placement="leftStart" trigger="click" speaker={speaker}>
        <IconButton
          appearance="subtle"
          icon={<i className="bi bi-three-dots" />}
        />
      </Whisper>
    </Table.Cell>
  );
};

const InvoiceAccountActionCell: React.FC<
  CellProps<Row | CardToCardAccountRow | InvoiceAccountRow> & {
    onChange: () => void;
  }
> = ({ rowData, onChange, ...props }) => {
  if (!rowData) return null;
  const speaker = (
    { onClose, left, top, className }: any,
    ref: React.Ref<any>,
  ) => (
    <Popover ref={ref} className={className} style={{ left, top }} full>
      <Dropdown.Menu>
        <Dropdown.Item
          onSelect={function () {
            onClose();
            ModalsService.createModal(AddInvoiceAccountModel, {
              defaultValues: {
                ...(rowData as InvoiceAccountRow),
                signing: [
                  {
                    type: 'exist',
                    file: (rowData as InvoiceAccountRow).signing,
                  },
                ],
                stamp: (rowData as InvoiceAccountRow).stamp
                  ? [
                      {
                        type: 'exist',
                        file: (rowData as InvoiceAccountRow)
                          .stamp as CleanFileDto,
                      },
                    ]
                  : undefined,
                currencies: (rowData as InvoiceAccountRow).currencies.map(
                  (value) => ({ value: value }),
                ),
                vatValue: {
                  label:
                    vatValueNames[
                      (rowData as InvoiceAccountRow)
                        ?.vatValue as keyof typeof vatValueNames
                    ],
                  value: (rowData as InvoiceAccountRow).vatValue || 0,
                },
              },
              onSubmit: async (result) => {
                await api.put(
                  `/top-up/methods/invoice/accounts/${(rowData as InvoiceAccountRow).uuid}`,
                  result,
                );
              },
              buttonTitle: 'Обновить',
            })
              .then(() => {
                AlertService.success();
              })
              .then(() => {
                onChange();
              });
          }}
        >
          <i className="bi bi-currency-exchange" />
          <span className="ps-2">Изменить разреш. счета</span>
        </Dropdown.Item>
        <Dropdown.Item
          onSelect={function () {
            onClose();
            api
              .delete(
                `/top-up/methods/${rowData.method}/accounts/${rowData.id}`,
              )
              .then(() => {
                AlertService.success();
              })
              .then(() => {
                onChange();
              });
          }}
        >
          <i className="bi bi-trash" />
          <span className="ps-2">Удалить</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Popover>
  );

  return (
    <Table.Cell
      {...props}
      className="link-group no-padding-cell pt-2"
      align="right"
    >
      <Whisper placement="leftStart" trigger="click" speaker={speaker}>
        <IconButton
          appearance="subtle"
          icon={<i className="bi bi-three-dots" />}
        />
      </Whisper>
    </Table.Cell>
  );
};

const EditMethodActionCell: React.FC<
  CellProps<Row> & { onClick: () => void }
> = ({ onClick, ...props }) => {
  return (
    <Table.Cell
      {...props}
      className="link-group no-padding-cell pt-2"
      align="right"
    >
      <IconButton
        appearance="subtle"
        icon={<i className="bi bi-pencil" />}
        type="button"
        onClick={onClick}
      />
    </Table.Cell>
  );
};

const ActionCell: React.FC<
  CellProps<Row | CardToCardAccountRow | InvoiceAccountRow | SbpAccountRow> & {
    onChange: () => void;
  }
> = (props) => {
  if (!props.rowData) return null;

  function handleAlipayEdit() {
    ModalsService.createModal(AlipayMethodModal, {
      account: props.rowData as AlipayAccount,
    })
      .then(() => {
        AlertService.success();
      })
      .then(() => {
        props.onChange();
      });
  }

  function handleWeChatEdit() {
    ModalsService.createModal(WeChatMethodModal, {
      account: props.rowData as WeChatAccount,
    })
      .then(() => {
        AlertService.success();
      })
      .then(() => {
        props.onChange();
      });
  }

  const renderedCell = (() => {
    switch (props.rowData.type) {
      case 'method':
        switch (props.rowData.method) {
          case 'sberbank':
          case 'alfabank':
          case 'tinkoff':
            return <BankMethodActionCell {...props} />;
          case 'alipay':
            return (
              <EditMethodActionCell
                {...{ ...props, rowData: props.rowData }}
                onClick={handleAlipayEdit}
              />
            );
          case 'wechat':
            return (
              <EditMethodActionCell
                {...{ ...props, rowData: props.rowData }}
                onClick={handleWeChatEdit}
              />
            );
          case 'invoice':
            return (
              <InvoiceMethodActionCell
                {...{ ...props, rowData: props.rowData }}
              />
            );

          case 'sbp':
            return (
              <SbpMethodActionCell {...{ ...props, rowData: props.rowData }} />
            );

          default:
            return (
              <GenericActionCell {...{ ...props, rowData: props.rowData }} />
            );
        }
      case 'account':
        switch (props.rowData.method) {
          case 'sberbank':
          case 'alfabank':
          case 'tinkoff':
            return (
              <BankAccountActionCell
                {...{ ...props, rowData: props.rowData }}
              />
            );
          case 'invoice':
            return (
              <InvoiceAccountActionCell
                {...{ ...props, rowData: props.rowData }}
              />
            );
          case 'sbp':
            return (
              <SbpAccountActionCell {...{ ...props, rowData: props.rowData }} />
            );
        }
    }
  })();

  return (
    <Rbac object={RbacObject.TopUpMethod} action={'write:admin'}>
      {renderedCell}
    </Rbac>
  );
};

type CardToCardMethod = {
  disabled: boolean;
  name: 'sberbank' | 'alfabank' | 'tinkoff';
  fee: number;
};

export type Currency = 'usd' | 'cny' | 'rub';

type AlipayMethod = {
  disabled: boolean;
  name: 'alipay';
  currencies: Currency[];
  fee?: number;
};

type WeChatMethod = {
  disabled: boolean;
  name: 'wechat';
  currencies: Currency[];
  fee?: number;
};

type CashMethod = {
  disabled: boolean;
  name: 'cash' | 'cash-company';
  currencies: Currency[];
  fee?: number;
};

type InvoiceMethod = {
  disabled: boolean;
  name: 'invoice';
  currencies: Currency[];
  paymentName?: string;
  fee?: number;
};

type InvoiceCustomsMethod = {
  disabled: boolean;
  name: 'invoice_customs';
  currencies: Currency[];
  paymentName?: string;
  fee?: number;
};

type PayKeeperMethods = {
  disabled: boolean;
  name: 'pay-keeper_card' | 'pay-keeper_sbp';
  currencies: Currency[];
  fee?: number;
};

type CryptomusMethods = {
  disabled: boolean;
  name: 'cryptomus';
  currencies: Currency[];
  fee?: number;
};

type CryptomusWalletMethods = {
  disabled: boolean;
  name: 'cryptomus-wallet';
  currencies: Currency[];
  fee?: number;
};

type CryptocloudMethods = {
  disabled: boolean;
  name: 'cryptocloud';
  currencies: Currency[];
  fee?: number;
};

type PayselectionMethods = {
  disabled: boolean;
  name: 'payselection';
  currencies: Currency[];
  fee?: number;
};

type WataMethod = {
  disabled: boolean;
  name: 'wata';
  currencies: Currency[];
  fee?: number;
};

type WataSbpMethod = {
  disabled: boolean;
  name: 'wata-sbp';
  currencies: Currency[];
  fee?: number;
};

type SbpMethod = {
  disabled: boolean;
  name: 'sbp';
  currencies: Currency[];
  fee?: number;
};

type SbpAccount = {
  name: string;
  file: CleanFileDto | null;
  disabled: boolean;
  uuid: string;
};

type InvoiceAccount = {
  disabled: boolean;
  uuid: string;
  currencies: Currency[];
  name: string;
  shortName: string;
  inn: string;
  account: string;
  address: string;
  bankName: string;
  bankBic: string;
  bankAccount: string;
  bankAddress: string;
  bankInn: string;
  kpp?: string;
  ogrn?: string;
  signing: CleanFileDto;
  stamp?: CleanFileDto;
};

type MethodWithAccount =
  | (CardToCardMethod & {
      accounts: Array<CardToCardAccount & { currencies: Currency[] }>;
    })
  | (AlipayMethod & { account: AlipayAccount | null })
  | (WeChatMethod & { account: WeChatAdminAccount | null })
  | CashMethod
  | (InvoiceMethod & { accounts: Array<InvoiceAccount> })
  | InvoiceCustomsMethod
  | PayKeeperMethods
  | CryptocloudMethods
  | CryptomusMethods
  | CryptomusWalletMethods
  | PayselectionMethods
  | WataMethod
  | WataSbpMethod
  | (SbpMethod & { accounts: Array<SbpAccount> });

export type CardToCardAccountRow = {
  type: 'account';
  method: 'sberbank' | 'alfabank' | 'tinkoff';
  id: string;
  disabled: boolean;
  cardNumber: string;
  cardHolderName: string;
  limit: string;
  remainingLimit: string;
  currencies: Currency[];
};

type CardToCardRow = {
  id: string;
  type: 'method';
  method: 'sberbank' | 'alfabank' | 'tinkoff';
  disabled: boolean;
  children: CardToCardAccountRow[];
  fee: number;
};

type AlipayRow = {
  id: string;
  type: 'method';
  method: 'alipay';
  disabled: boolean;
  currencies: Currency[];
  fee?: number;
} & Partial<AlipayAccount>;

type WeChatRow = {
  id: string;
  type: 'method';
  method: 'wechat';
  disabled: boolean;
  currencies: Currency[];
  fee?: number;
} & Partial<WeChatAdminAccount>;

type CashRow = {
  id: string;
  type: 'method';
  method: 'cash' | 'cash-company';
  currencies: Currency[];
  fee?: number;
  disabled: boolean;
};

type InvoiceRow = {
  id: string;
  type: 'method';
  method: 'invoice';
  disabled: boolean;
  currencies: Currency[];
  paymentName?: string;
  fee?: number;
  children: Array<InvoiceAccountRow>;
};

type InvoiceCustomsRow = {
  id: string;
  type: 'method';
  method: 'invoice_customs';
  disabled: boolean;
  currencies: Currency[];
  paymentName?: string;
  fee?: number;
};

export type InvoiceAccountRow = {
  type: 'account';
  method: 'invoice';
  id: string;
  uuid: string;
  disabled: boolean;
  cardHolderName: string;
  currencies: Currency[];
  name: string;
  shortName: string;
  inn: string;
  account: string;
  address: string;
  bankName: string;
  bankBic: string;
  bankAccount: string;
  bankAddress: string;
  bankInn: string;
  kpp?: string;
  ogrn?: string;
  signing: CleanFileDto;
  stamp?: CleanFileDto;
  vatValue?: number;
  fee?: number;
};

type PayKeeperRow = {
  id: string;
  type: 'method';
  method: 'pay-keeper_card' | 'pay-keeper_sbp';
  disabled: boolean;
  currencies: Currency[];
  fee?: number;
};

type CryptomusRow = {
  id: string;
  type: 'method';
  method: 'cryptomus';
  disabled: boolean;
  currencies: Currency[];
  fee?: number;
};

type CryptomusWalletRow = {
  id: string;
  type: 'method';
  method: 'cryptomus-wallet';
  disabled: boolean;
  currencies: Currency[];
  fee?: number;
};
type CryptocloudRow = {
  id: string;
  type: 'method';
  method: 'cryptocloud';
  disabled: boolean;
  currencies: Currency[];
  fee?: number;
};

type PayselectionRow = {
  id: string;
  type: 'method';
  method: 'payselection';
  disabled: boolean;
  currencies: Currency[];
  fee?: number;
};

type WataRow = {
  id: string;
  type: 'method';
  method: 'wata';
  disabled: boolean;
  currencies: Currency[];
  fee?: number;
};

type WataSbpRow = {
  id: string;
  type: 'method';
  method: 'wata-sbp';
  disabled: boolean;
  currencies: Currency[];
  fee?: number;
};

export type Row =
  | CardToCardRow
  | AlipayRow
  | WeChatRow
  | CashRow
  | InvoiceRow
  | InvoiceCustomsRow
  | PayKeeperRow
  | CryptomusRow
  | CryptomusWalletRow
  | CryptocloudRow
  | PayselectionRow
  | WataRow
  | WataSbpRow
  | SbpRow;

type Form = {
  topupMethod: string;
  currencies: string;
  targetCurrencies: string;
};

export const TopUpAccounts: React.FC = function () {
  const { data, error, mutate } = useSWR<{ list: MethodWithAccount[] }>(
    '/top-up/accounts',
    fetcher,
  );

  const loading = !data && !error;

  const { register, watch } = useForm<Form>({
    defaultValues: {
      topupMethod: '',
      currencies: '',
      targetCurrencies: '',
    },
  });

  // Значения формы, за которыми мы будем следить
  const formValues = watch();

  // Строим rows и одновременно фильтруем по значению формы
  const rows: Row[] = React.useMemo(() => {
    return (
      data?.list.reduce<Row[]>((rows, method) => {
        let row: Row | null = null;
        switch (method.name) {
          case 'alfabank':
          case 'sberbank':
          case 'tinkoff': {
            row = {
              id: method.name,
              type: 'method',
              method: method.name,
              disabled: method.disabled,
              fee: method.fee,
              children: method.accounts.map((account) => ({
                type: 'account',
                method: method.name,
                id: account.id,
                disabled: account.disabled,
                cardNumber: account.cardNumber,
                cardHolderName: account.cardHolderName,
                limit: account.limit,
                remainingLimit: account.remainingLimit,
                currencies: account.currencies,
              })),
            };
            break;
          }

          case 'alipay':
          case 'wechat': {
            row = {
              id: method.name,
              type: 'method',
              method: method.name,
              disabled: method.disabled,
              currencies: method.currencies,
              fee: method.fee,
              ...method.account,
            };
            break;
          }

          case 'invoice': {
            row = {
              id: method.name,
              type: 'method',
              method: method.name,
              disabled: method.disabled,
              currencies: method.currencies,
              paymentName: method.paymentName,
              fee: method.fee,
              children: method.accounts.map((account) => ({
                ...account,
                id: account.uuid,
                method: 'invoice',
                cardHolderName: account.shortName,
                currencies: account.currencies,
                cardNumber: account.account,
                type: 'account',
              })),
            };
            break;
          }

          case 'invoice_customs': {
            row = {
              id: method.name,
              type: 'method',
              method: method.name,
              disabled: method.disabled,
              currencies: method.currencies,
              paymentName: method.paymentName,
              fee: method.fee,
            };
            break;
          }

          case 'sbp': {
            row = {
              id: method.name,
              type: 'method',
              method: method.name,
              disabled: method.disabled,
              currencies: method.currencies,
              fee: method.fee,
              children: method.accounts.map((account) => ({
                ...account,
                id: account.uuid,
                method: 'sbp',
                type: 'account',
                currencies: method.currencies,
                cardHolderName: account.name,
              })),
            };
            break;
          }

          default: {
            row = {
              id: method.name,
              type: 'method',
              method: method.name,
              disabled: method.disabled,
              currencies: method.currencies,
              fee: method.fee,
            };
          }
        }

        if (row) {
          // Фильтруем по методу пополнения (если поле заполнено)
          const isMethodMatch =
            !formValues.topupMethod ||
            getTopUpMethodTitle(row.method)
              ?.toLowerCase()
              .includes(formValues.topupMethod.toLowerCase()) ||
            row.method
              .toLowerCase()
              .includes(formValues.topupMethod.toLowerCase());

          // Получить валюты
          const currencies = getCurrencyByMethod(row.method);

          // Фильтруем по валютам (если поле заполнено)
          const isCurrencyMatch =
            !formValues.currencies ||
            (currencies &&
              currencies
                ?.toLowerCase()
                .includes(formValues.currencies.toLowerCase()));

          const targetCurrencies = (() => {
            if (!row.hasOwnProperty('currencies')) return '';

            return (row as Row & { currencies: Array<string> }).currencies
              .join(' / ')
              .toLowerCase();
          })();

          // Фильтруем по разрешенным валютам (если поле заполнено)
          const isTargetCurrencyMatch =
            !formValues.targetCurrencies ||
            (targetCurrencies &&
              targetCurrencies.includes(formValues.targetCurrencies));

          // Если оба условия выполняются, добавляем row в массив
          if (isMethodMatch && isCurrencyMatch && isTargetCurrencyMatch) {
            rows.push(row);
          }
        }

        return rows;
      }, []) ?? []
    );
  }, [data, formValues]);

  const handleDisable: OnChangeDisableCell = async function (data, disabled) {
    switch (data.type) {
      case 'account':
        await api.post(
          `/top-up/methods/${data.method}/accounts/${data.id}/disabled`,
          { disabled },
        );
        await mutate();
        break;
      case 'method':
        await api.post(`/top-up/methods/${data.method}/disabled`, { disabled });
        await mutate();
        break;
    }
  };

  return (
    <div className="d-flex flex-column gap-3" style={{ paddingBottom: '10em' }}>
      <div className="w-100 d-flex flex-row justify-content-between">
        <div className="d-flex flex-row gap-2">
          <input
            style={{ fontSize: '14px' }}
            className="form-control col-auto w-25"
            placeholder="Тип пополнения"
            {...register('topupMethod')}
          />
          <input
            style={{ fontSize: '14px' }}
            className="form-control col-auto w-25"
            placeholder="Валюта"
            {...register('currencies')}
          />
          <input
            style={{ fontSize: '14px' }}
            className="form-control col-auto w-25"
            placeholder="Разреш. счета"
            {...register('targetCurrencies')}
          />
        </div>
      </div>
      <Table
        isTree
        headerHeight={57}
        autoHeight
        loading={loading}
        data={rows}
        defaultExpandAllRows={true}
        rowKey="id"
        locale={{
          loading: 'Загрузка',
          emptyMessage: 'Нет способов пополнения',
        }}
      >
        <Table.Column width={30}>
          <Table.HeaderCell> </Table.HeaderCell>
          <Table.Cell rowKey={'id'} />
        </Table.Column>
        <Table.Column width={250} fullText={true}>
          <Table.HeaderCell>Тип пополнения</Table.HeaderCell>
          <NameCell />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>№ счета</Table.HeaderCell>
          <Table.Cell dataKey="cardNumber" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Получатель</Table.HeaderCell>
          <Table.Cell dataKey="cardHolderName" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Валюта</Table.HeaderCell>
          <CurrenciesCell />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Разреш. счета</Table.HeaderCell>
          <TargetCurrenciesCell />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Лимит</Table.HeaderCell>
          <LimitCell />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Комиссия</Table.HeaderCell>
          <FeeCell />
        </Table.Column>
        <Table.Column flexGrow={0}>
          <Table.HeaderCell>Доступ</Table.HeaderCell>
          <DisableCell
            // @ts-expect-error todo
            onChange={handleDisable}
          />
        </Table.Column>
        <Table.Column flexGrow={0}>
          <Table.HeaderCell children="" />
          <ActionCell onChange={() => mutate()} />
        </Table.Column>
      </Table>
    </div>
  );
};
