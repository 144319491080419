import {
  CleanDeliveryOrder,
  CleanTransportation,
  RbacObject,
} from '../../../export-types/cleaned-types';
import {
  calculatedPrice,
  DeliveryOrdersTabItem,
} from './delivery-orders-tab-item';
import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useRbac } from '../../common/Rbac';
import { Link, useNavigate } from 'react-router-dom';
import { TariffCell } from '../../common/cards/tariff-cell-card.component';
import moment from 'moment/moment';
import { FormattedMessage } from 'react-intl';
import { IdCell } from '../../common/cards/id-cell-card.component';
import { LocalDelivery } from '../../common/cards/local-delivery-cell-card.component';
import { Manager } from '../../common/cards/manager-cell-card.component';
import { toMoney } from '../../../utils/common.utils';
import { useProfile } from '../../../api/profile';
import { UserInternalIdCell } from '../../common/cards/id-user-cell-card.component';
import { ExpandButton, Row, SubRow } from '../../row';
import { ImagesCell } from '../../common/cards/images-cell-card.component';
import { DeliveryPeriodCell } from '../../common/cards/delivery-date-cell-card.component';
import { WeightVolumeCell } from '../../common/cards/weight-volume-cell-card.component';
import { PriceCell } from '../../common/cards/price-cell-card.component';
import { PaidStatus } from '../../common/paid-status.component';

type TransportationTabItemProps = {
  data: CleanTransportation;
  status: 'assembly' | 'sentToRussia';
  collapsed?: boolean;
  defaultCollapsed?: boolean;
  onCollapsedChange?: () => void;
};

export function TransportationTabItem(props: TransportationTabItemProps) {
  const { data: profile } = useProfile();
  const { hasPermission } = useRbac();
  const navigate = useNavigate();

  const href = `/transportations/${props.data.id}`;

  const canReadExtendedData = hasPermission(
    RbacObject.DeliveryOrder,
    'read:finances-client',
  );

  if (props.data.lines.length === 0) return null;

  if (props.data.type === 'standalone') {
    const deliveryOrder = props.data.lines[0].deliveryOrder;

    return (
      <DeliveryOrdersTabItem
        data={deliveryOrder}
        onCollapsedChange={props.onCollapsedChange}
        collapsed={props.collapsed}
        defaultCollapsed={props.defaultCollapsed}
        status={props.status}
      />
    );
  }

  const handleClick = useCallback(() => {
    navigate(href);
  }, [navigate, href]);

  return (
    <Row
      onClick={handleClick}
      collapsed={props.collapsed}
      defaultCollapsed={props.defaultCollapsed}
      onCollapsedChange={props.onCollapsedChange}
      subRows={props.data.lines.map(
        ({ deliveryOrder }) =>
          deliveryOrder && (
            <DeliveryOrderSubRow
              data={deliveryOrder}
              key={deliveryOrder.id}
              status={props.status}
            />
          ),
      )}
    >
      {({ collapsed, setCollapsed }) => (
        <>
          {canReadExtendedData && (
            <div className="col-1">{props.data.supplier?.name ?? ''}</div>
          )}
          <div className="col-1">
            <Link
              to={href}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {props.data.name}
            </Link>
          </div>
          <div className="col-1" />
          <div className="col-1">
            <ExpandButton
              onToggle={() => setCollapsed(!collapsed)}
              collapsed={collapsed}
            />
          </div>
          {!props.data.waybill && (
            <>
              <div className="col-1">
                <TariffCell
                  deliveryMethod={{
                    method: props.data.methodType,
                    deliveryPeriodFrom: props.data.deliveryPeriodFrom,
                    deliveryPeriodTo: props.data.deliveryPeriodTo ?? undefined,
                  }}
                />
              </div>
              <div className="col-2 full-text">
                <div className="color-gray-800 fs-16 fw-500">
                  {moment(props.data.createdAt).format('DD.MM.YYYY')}
                </div>
                <div className="color-gray-600 fs-14">
                  <FormattedMessage
                    defaultMessage="Поступил на комплектацию"
                    id="order.status-label.ReceivedForCompletion"
                    description="Статус заказа"
                  />
                </div>
              </div>
              {canReadExtendedData && <div className="col-2" />}
              {profile?.role === 'admin' ||
              ((profile?.accesses.includes('purchase') ||
                profile?.accesses.includes('warehouse')) &&
                !!profile.supplier) ? (
                <div
                  className={classNames(
                    'col-1',
                    canReadExtendedData ? '' : 'text-end',
                    props.data?.destination?.city !== 'Москва'
                      ? 'fw-bold'
                      : null,
                  )}
                >
                  {props.data?.destination?.city}
                </div>
              ) : null}
              {profile && !profile.accesses.includes('fulfillment') && (
                <div className="col text-end">
                  <div className="color-gray-800 fs-16 fw-500  text-truncate">
                    {toMoney(
                      props.data.productPrice!,
                      props.data.supplier?.purchaseCurrency || 'cny',
                    )}
                  </div>
                  <div className="color-gray-600 fs-14 fw-400">
                    <FormattedMessage
                      defaultMessage="Стоимость товаров"
                      id="delivery.label.priceOfGoods"
                      description="Стоимость товаров"
                    />
                  </div>
                </div>
              )}
            </>
          )}
          {props.data.waybill && (
            <>
              <div
                className={classNames('col-1', 'd-flex')}
                onClick={(e) => e.stopPropagation()}
              >
                <ImagesCell imagesList={props.data.waybill.packedFiles} />
              </div>
              <div className="col-2 d-flex align-items-center">
                <DeliveryPeriodCell
                  deliveryMethod={{
                    method: props.data.methodType,
                    deliveryPeriodTo: props.data.deliveryPeriodTo,
                  }}
                  sentDate={props.data.waybill.createdAt}
                  addHover={true}
                />
              </div>
              {canReadExtendedData && <div className="col-1" />}
              {profile?.role === 'admin' ||
              ((profile?.accesses.includes('purchase') ||
                profile?.accesses.includes('warehouse')) &&
                !!profile.supplier) ? (
                <div
                  className={classNames(
                    'col-1',
                    canReadExtendedData ? '' : 'text-end',
                    props.data?.destination?.city !== 'Москва'
                      ? 'fw-bold'
                      : null,
                  )}
                >
                  {props.data?.destination?.city}
                </div>
              ) : null}
              <div
                className={classNames(
                  canReadExtendedData ? 'col-1' : 'col-2 text-end',
                )}
              >
                <WeightVolumeCell
                  weight={props.data.waybill.weight}
                  volume={props.data.waybill.volume}
                />
              </div>
            </>
          )}
        </>
      )}
    </Row>
  );
}

function DeliveryOrderSubRow(props: {
  data: CleanDeliveryOrder;
  status: 'assembly' | 'sentToRussia';
}) {
  const { data: profile } = useProfile();
  const { hasPermission } = useRbac();

  const canReadExtendedData = hasPermission(
    RbacObject.DeliveryOrder,
    'read:finances-client',
  );

  const ItemPaidStatus = React.useMemo(() => {
    if (!props.data.price)
      return <PaidStatus className="ms-3" type="preliminaryPrice" />;

    if (!!props.data.price && !props.data.paid)
      return <PaidStatus className="ms-3" type="waiting" />;

    if (props.data.paid) return <PaidStatus className="ms-3" type="paid" />;
  }, [props.data.paid, props.data.price]);

  return (
    <SubRow>
      {canReadExtendedData && <div className="col-1" />}
      <div className="col-1">
        <Link
          to={`/deliveries/delivery-order/${props.data.id}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <IdCell internalId={props.data.internalId} type={'delivery'} />
        </Link>
      </div>
      <div className="col-1">
        <UserInternalIdCell user={props.data.user} />
      </div>
      <div className="col-1" />
      {props.status === 'assembly' && (
        <>
          <div className="col-1" />
          <div className="col-2 full-text">
            <div className="color-gray-800 fs-16 fw-500">
              {moment(props.data.createdAt).format('DD.MM.YYYY')}
            </div>
            <div className="color-gray-600 fs-14">
              <FormattedMessage
                defaultMessage="Поступил на комплектацию"
                id="order.status-label.ReceivedForCompletion"
                description="Статус заказа"
              />
            </div>
          </div>
          {canReadExtendedData && (
            <div className="col-2">
              <LocalDelivery
                localDelivery={props.data.localDeliveryMethod}
                addHover={true}
              />
            </div>
          )}
          {canReadExtendedData && props.data.user?.manager ? (
            <div className="col-1">
              <Manager manager={props.data.user?.manager} />
            </div>
          ) : (
            <div className="col-1" />
          )}
          {profile?.role === 'admin' ||
          ((profile?.accesses.includes('purchase') ||
            profile?.accesses.includes('warehouse')) &&
            !!profile.supplier) ? (
            <div className="col-1" />
          ) : null}
          {profile && !profile.accesses.includes('fulfillment') && (
            <div className="col text-end">
              <div className="color-gray-800 fs-16 fw-500  text-truncate">
                {toMoney(
                  props.data.moderateProductPrice!,
                  props.data.supplier?.purchaseCurrency || 'cny',
                )}
              </div>
              <div className="color-gray-600 fs-14 fw-400">
                <FormattedMessage
                  defaultMessage="Стоимость товаров"
                  id="delivery.label.priceOfGoods"
                  description="Стоимость товаров"
                />
              </div>
            </div>
          )}
        </>
      )}
      {props.status === 'sentToRussia' && (
        <>
          <div className="col-1" />
          <div className="col-1" />
          {canReadExtendedData && (
            <div className="col-2">
              <LocalDelivery
                localDelivery={props.data.localDeliveryMethod}
                addHover={true}
              />
            </div>
          )}
          <div
            className={classNames(
              canReadExtendedData ? 'col-1' : 'col-2 text-end',
            )}
          />
          {canReadExtendedData && props.data.user?.manager ? (
            <div className="col-1">
              <Manager manager={props.data.user?.manager} />
            </div>
          ) : (
            <div className="col-1" />
          )}
          {canReadExtendedData && (
            <div className="col justify-content-end d-flex align-items-center">
              <PriceCell
                price={calculatedPrice(props.data)?.toString()}
                currency={'usd'}
              />
              {ItemPaidStatus}
            </div>
          )}
        </>
      )}
    </SubRow>
  );
}
