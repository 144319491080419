import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { required } from '../../../utils/validations';
import { InputWrapper } from '../../common/input-wrapper';
import { fetcher } from '../../../services/api';
import { AlertService } from '../../../services/alert.service';
import { useModalClose } from '../../../utils/useModalClose';
import { trpcSwr } from '../../../api/trpc';

type Form = {
  title: string;
  value: string;
};

export type ModalProps = {
  close: () => void;
  reject: () => void;
  item?: Form & { id: number };
};

export const AddUtm: React.FC<ModalProps> = ({ close, reject, item }) => {
  const {
    formState: { isSubmitting },
    handleSubmit,
    control,
  } = useForm<Form>({
    defaultValues: item,
  });

  const { trigger: triggerUpdate } = trpcSwr.utmAdmin.update.useSWRMutation({
    fetcher,
  });
  const { trigger: triggerCreate } = trpcSwr.utmAdmin.create.useSWRMutation({
    fetcher,
  });

  const onSubmit = handleSubmit(async (form) => {
    if (item) {
      await triggerUpdate({
        id: item.id,
        data: form,
      });
    } else {
      await triggerCreate(form);
    }
    AlertService.success();
    close();
  });

  const modalRef = useModalClose(reject);

  return (
    <div className="modal-dialog" ref={modalRef}>
      <form className="modal-content" onSubmit={onSubmit}>
        <div className="modal-header border-bottom-0 p-4">
          <h3>{item ? 'Редактировать' : 'Добавить'} utm метку</h3>
          <button
            type="button"
            className="btn-close"
            onClick={() => reject()}
            disabled={isSubmitting}
          />
        </div>
        <div className="modal-body p-4">
          <Controller
            control={control}
            name="value"
            rules={{
              ...required,
            }}
            render={({ field, fieldState }) => (
              <InputWrapper
                title="Значение utm_referrer"
                error={fieldState.error?.message}
                required
              >
                <input className="form-control mb-3" type="text" {...field} />
              </InputWrapper>
            )}
          />
          <Controller
            control={control}
            name="title"
            render={({ field, fieldState }) => (
              <InputWrapper title="Заголовок" error={fieldState.error?.message}>
                <input className="form-control mb-3" type="text" {...field} />
              </InputWrapper>
            )}
          />
        </div>
        <div className="modal-footer justify-content-end">
          <button
            type="submit"
            className="btn btn-success"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : item ? (
              'Сохранить'
            ) : (
              'Добавить'
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
