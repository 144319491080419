import Table, { CellProps } from 'rsuite/Table';
import { CleanTransaction } from '../../../export-types/cleaned-types';
import { ModalsService } from '../../../services/modals.service';
import { InvoceForPaymentModal } from './invoce-for-payment.modal.component';
import PngInvoceForPayment from '../../../assets/invoce-for-payment.png';
import React from 'react';

export const InvoceForPayment = ({
  rowData,
  ...props
}: CellProps<CleanTransaction>) => {
  if (!rowData) return null;
  if (rowData.journal !== 'depositByInvoice') {
    return (
      <Table.Cell {...props} className="no-padding-cell pt-2" align="center" />
    );
  }

  const handleClick = async () => {
    await ModalsService.createModal(InvoceForPaymentModal, {
      transaction: rowData.id,
    });
  };

  return (
    <Table.Cell {...props} className="no-padding-cell pt-2 ps-2">
      <div className="cursor-pointer" onClick={handleClick}>
        <img
          className="rounded fit-cover"
          src={PngInvoceForPayment}
          height={32}
        />
      </div>
    </Table.Cell>
  );
};
