import { Button, Drawer } from 'rsuite';
import React from 'react';
import { trpcSwr } from '../../../api/trpc';
import MyLoader from '../../common/loader.component';
import IconButton from 'rsuite/IconButton';
import { FieldsForm, FilterForm } from './transactions-history-table.component';
import { AlertService } from '../../../services/alert.service';

interface Props {
  open: boolean;
  onClose: () => void;
  onSelect: (
    parameters: Omit<FilterForm, 'filters'> & {
      filters: Omit<FilterForm['filters'], 'dates'> & {
        dates: [string, string] | undefined;
      };
    } & FieldsForm,
  ) => void;
}

export const SavedFiltersDrawer: React.FC<Props> = ({
  open,
  onClose,
  onSelect,
}) => {
  const { data, mutate, isLoading } = trpcSwr.savedFilters.list.useSWR();
  const { trigger: triggerDelete } =
    trpcSwr.savedFilters.deleteFilter.useSWRMutation();
  return (
    <Drawer backdrop={true} open={open} onClose={onClose}>
      <Drawer.Header>
        <Drawer.Title>Сохраненные фильтры</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body className="gap-2">
        {isLoading ? (
          <MyLoader />
        ) : (
          <>
            {data?.map((filter) => {
              return (
                <div className="d-flex pb-2 gap-2" key={filter.id}>
                  <Button
                    className="w-75"
                    onClick={() => {
                      try {
                        onSelect(filter.parameters);
                        onClose();
                      } catch (error) {
                        AlertService.error(error as string);
                      }
                    }}
                  >
                    {filter.name}
                  </Button>
                  <IconButton
                    appearance="subtle"
                    icon={<i className="bi bi-x" />}
                    onClick={async () => {
                      await triggerDelete({ id: filter.id });
                      mutate();
                    }}
                  />
                </div>
              );
            })}
          </>
        )}
      </Drawer.Body>
    </Drawer>
  );
};
