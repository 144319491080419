import { useParams, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { CleanPurchaseOrder } from '../../../export-types/cleaned-types';
import { fetcher } from '../../../services/api';
import { IdCell } from '../../common/cards/id-cell-card.component';
import { Gallery } from '../../common/gallery/gallery';
import { getFileV2ApiUrl } from '../../../utils/common.utils';
import PdfIcon from '../../../assets/pdf-file.svg';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

/** Подробная информация о заказе с возможностью последующего редактирования / подтверждения на складе */
export const TerminalPurchaseOrder = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  // Получить данные о заказе и запретить доступ, если заказа нет
  const { data: order } = useSWR<CleanPurchaseOrder>(
    `/purchase-order/${id}`,
    fetcher,
  );

  if (!order) return null;

  // Получить дату принятия на склад
  const date = moment(order.receiptDate).format('DD.MM.YYYY');

  return (
    <div
      style={{ height: 'calc(100% - 90px)' }}
      className="d-flex flex-fill flex-column justify-content-between p-4"
    >
      {/* Контейнер с обложкой и названием заказа */}
      <div className="d-flex flex-column align-items-center gap-3">
        <Gallery className="mt-2 overflow-x-auto w-100 d-flex justify-content-around gap-2">
          {order.requestFilesV2.map((file, index) => (
            <a
              href={getFileV2ApiUrl(file.id)}
              key={index}
              data-iframe={file.type === 'pdf'}
            >
              <img
                className="rounded fit-cover me-2"
                src={file.type === 'pdf' ? PdfIcon : getFileV2ApiUrl(file.id)}
                width={202}
                height={178}
                alt=""
              />
            </a>
          ))}
        </Gallery>
        <h2>
          <IdCell internalId={order.internalId} type={order.type} />
        </h2>
      </div>

      {/* Контейнер с доп.информацией о заказе */}
      <div className="d-flex flex-column gap-3">
        {/* Код владельца заказа */}
        {order.user && (
          <div className="d-flex gap-2 align-items-center">
            <i className="bi bi-person-fill text-success" /> ID
            <hr className="vr m-0" />
            {order.user.internalId}
          </div>
        )}

        {/* Статус заказа */}
        <div className="d-flex gap-2 align-items-center">
          <i className="bi bi-circle text-success" />{' '}
          <FormattedMessage
            defaultMessage="Статус"
            id="terminal.label.status"
            description="Названия Пункта"
          />
          <hr className="vr m-0" />
          {order.status === 'onTheWayToStock' ? (
            <FormattedMessage
              defaultMessage="В пути"
              id="terminal.value.onWay"
              description="Значение Пункта"
            />
          ) : (
            <FormattedMessage
              defaultMessage="На складе"
              id="terminal.value.inStorage"
              description="Значение Пункта"
            />
          )}
        </div>

        {/* Время принятия на склад */}
        {order.receiptDate && order.status !== 'onTheWayToStock' && (
          <div className="d-flex gap-2 align-items-center">
            <i className="bi bi-calendar text-success" />{' '}
            <FormattedMessage
              defaultMessage="Приятно на складе"
              id="terminal.label.accaptedInStorage"
              description="Названия Пункта"
            />
            <hr className="vr m-0" />
            {date}
          </div>
        )}

        {/* Место хранения заказа на складе */}
        {order.storageLocation && order.status !== 'onTheWayToStock' && (
          <div className="d-flex gap-2 align-items-center">
            <i className="bi bi-archive-fill text-success" />{' '}
            <FormattedMessage
              defaultMessage="Место хранения"
              id="terminal.label.storageLocation"
              description="Информация о месте хранения"
            />
            <hr className="vr m-0" />
            {order.storageLocation}
          </div>
        )}

        {/* Транспортная компания, которая перевозит товар */}
        {order.transportCompany && order.status === 'onTheWayToStock' && (
          <div className="d-flex gap-2 align-items-center">
            <i className="bi bi-truck text-success" />{' '}
            <FormattedMessage
              defaultMessage="Перевозчик"
              id="terminal.label.transportCompany"
              description="Названия Пункта"
            />
            <hr className="vr m-0" />
            {order.transportCompany}
          </div>
        )}

        {/* Номер договора ТТН */}
        {order.landingBill && order.status === 'onTheWayToStock' && (
          <div className="d-flex gap-2 align-items-center">
            <i className="bi bi-file-text-fill text-success" /> ТТН
            <hr className="vr m-0" />
            {order.landingBill}
          </div>
        )}
      </div>

      {/* Контейнер с кнопками для заказа */}
      <div className="w-100 d-flex flex-column gap-2">
        <button
          className="btn btn-success"
          onClick={() => navigate(`/terminal/purchases/${id}/edit`)}
        >
          {order.status === 'onTheWayToStock' ? (
            <FormattedMessage
              defaultMessage="Приёмка"
              id="terminal.button.acceptance"
              description="Кнопка"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Редактировать"
              id="terminal.button.edit"
              description="Кнопка"
            />
          )}
        </button>

        <button
          className="btn btn-outline-secondary"
          onClick={() => navigate('/terminal/search')}
        >
          <FormattedMessage
            defaultMessage="Назад"
            id="terminal.button.back"
            description="Кнопка"
          />
        </button>
      </div>
    </div>
  );
};
