import React, { useEffect, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { decimalValidation, required } from '../../../utils/validations';
import useSWR from 'swr';
import { api, fetcher } from '../../../services/api';
import {
  getCurrencySymbol,
  getTopUpMethodTitle,
  toMoney,
} from '../../../utils/common.utils';
import { UserSelect, User } from '../../common/user-select';
import { InputWrapper } from '../../common/input-wrapper';
import ReactSelect from 'react-select';
import {
  AlipayAccount,
  CardToCardAccount,
  WeChatAdminAccount,
} from './top-up-dto';
import { AlertService } from '../../../services/alert.service';
import isDecimal from 'validator/es/lib/isDecimal';
import { useCurrencyCurrent } from '../../../services/currency.service';
import { useModalClose } from '../../../utils/useModalClose';
import { Decimal } from 'decimal.js';

type TopUpModalProps = {
  close: () => void;
};

export type CardToCardMethod = {
  id: string;
  name: 'sberbank' | 'alfabank' | 'tinkoff';
  options: Array<
    Omit<CardToCardAccount, 'disabled' | 'method'> & {
      name: CardToCardAccount['method'];
    }
  >;
  fee: number;
};

export type AlipayMethod = {
  id: string;
  name: 'alipay';
  account: Omit<AlipayAccount, 'disabled'>;
  fee: number;
};

export type WeChatMethod = {
  id: string;
  name: 'wechat';
  account: Omit<WeChatAdminAccount, 'disabled' | 'qrFile'>;
  fee: number;
};

export type CashMethod = {
  id: string;
  name: 'cash';
  fee: number;
};

export type AvailableMethods =
  | CardToCardMethod
  | AlipayMethod
  | WeChatMethod
  | CashMethod;

type Form = {
  user: User;
  targetCurrency: string;
  amount: string;
  topUpCurrency: string;
  method:
    | (Omit<CardToCardAccount, 'disabled' | 'method'> & {
        name: CardToCardAccount['method'];
      })
    | AlipayMethod
    | WeChatMethod
    | CashMethod;
  publicComment: string;
  privateComment: string;
  currencyRate?: string;
  feePercentage: number;
};
type MethodOption = Omit<CardToCardAccount, 'disabled' | 'method'> & {
  name: string;
  fee: number;
};
export const TopUpModal: React.FC<TopUpModalProps> = ({ close }) => {
  const { data: currency } = useCurrencyCurrent();

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    control,
    resetField,
    setValue,
  } = useForm<Form>({
    defaultValues: {
      topUpCurrency: 'rub',
    },
  });

  const handleSave = (data: Form) => {
    const currencyRate =
      data.topUpCurrency === 'rub' && data.currencyRate
        ? data.currencyRate
        : undefined;
    api
      .post('/top-up/admin/create', {
        ...data,
        currencyRate,
      })
      .then(() => {
        AlertService.success();
      })
      .then(() => {
        close();
      });
  };

  const [
    targetCurrency,
    amount,
    topUpCurrency,
    currencyRate,
    method,
    feePercentage,
  ] = watch([
    'targetCurrency',
    'amount',
    'topUpCurrency',
    'currencyRate',
    'method',
    'feePercentage',
  ]);

  const [debouncedAmount, setDebouncedAmount] = React.useState(amount);
  const [selectedCurrencyRate, setSelectedCurrencyRate] = useState('');
  const [selectedFeePercentage, setSelectedFeePercentage] = useState<
    number | null
  >(null);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedAmount(amount);
    }, 250);

    return () => {
      clearTimeout(handler);
    };
  }, [amount]);

  const { data: targetAmount } = useSWR<{ result: string }>(() => {
    if (!targetCurrency) return null;
    if (!topUpCurrency) return null;
    if (typeof debouncedAmount !== 'string' || !isDecimal(debouncedAmount))
      return null;
    if (!method) return null;

    return {
      url: '/top-up/calculate',
      params: {
        targetCurrency,
        amount: debouncedAmount,
        topUpCurrency,
        methodName: method.name,
      },
    };
  }, fetcher);

  const {
    data: availableMethodsData,
    isValidating: availableMethodsValidating,
  } = useSWR<{ list: AvailableMethods[] }>(
    {
      url: '/top-up/admin/available-methods',
      params: {
        targetCurrency,
        topUpCurrency,
      },
    },
    fetcher,
  );

  const availableMethods = React.useMemo(() => {
    return (availableMethodsData?.list || []).reduce(
      (acc, item) => {
        if (
          item.name === 'sberbank' ||
          item.name === 'alfabank' ||
          item.name === 'tinkoff'
        ) {
          acc.push({
            label: getTopUpMethodTitle(item.name) || item.name,
            options: item.options as MethodOption[],
            fee: item.fee,
          });
        } else {
          const otherMethodsIndex = acc.findIndex(
            (method) => method.label === 'Другие пополнения',
          );
          if (otherMethodsIndex !== -1) {
            acc[otherMethodsIndex].options.push(item as MethodOption);
          } else {
            acc.push({
              label: 'Другие пополнения',
              options: [item as MethodOption],
              fee: item.fee,
            });
          }
        }
        return acc;
      },
      [] as { label: string; options: MethodOption[]; fee: number }[],
    );
  }, [availableMethodsData]);

  const getFeeByMethodName = (methodName: string) => {
    if (!availableMethodsData || !availableMethodsData.list) {
      return null;
    }

    const foundMethod = availableMethodsData.list.find(
      (item) => item.name === methodName,
    );

    return foundMethod ? foundMethod.fee : null;
  };

  const availableAmountCurrency = React.useMemo(() => {
    if (!targetCurrency) {
      return [];
    }
    if (targetCurrency === 'usd') {
      return ['rub', 'usd'];
    }
    if (targetCurrency === 'cny') {
      return ['rub', 'cny'];
    }
    throw new Error(`Unknown target currency "${targetCurrency}"`);
  }, [targetCurrency]);

  const calculatedTargetAmount = useMemo(() => {
    if (!currencyRate) {
      return '';
    }

    const finalAmount = selectedFeePercentage
      ? new Decimal(amount)
          .mul(new Decimal(100))
          .div(new Decimal(100).add(selectedFeePercentage))
      : amount;

    if (topUpCurrency === 'rub') {
      if (finalAmount) {
        return new Decimal(Number(finalAmount) / Number(currencyRate)).toFixed(
          2,
        );
      }
    }
    return typeof targetAmount !== 'undefined' ? targetAmount.result : '';
  }, [topUpCurrency, amount, currencyRate, method, selectedFeePercentage]);

  const modalRef = useModalClose(close);

  useEffect(() => {
    if (currency) {
      let newRate = '';
      switch (targetCurrency) {
        case 'usd':
          newRate = new Decimal(currency.usdRubInternal).toFixed(2);
          break;
        case 'cny':
          newRate = new Decimal(currency.cnyRubInternal).toFixed(2);
          break;
        default:
          newRate = '';
      }

      if (targetCurrency === topUpCurrency) {
        newRate = '';
      }

      setSelectedCurrencyRate(newRate);
    }
  }, [targetCurrency, topUpCurrency, currency]);
  useEffect(() => {
    setValue('currencyRate', selectedCurrencyRate);
  }, [selectedCurrencyRate, setValue]);

  useEffect(() => {
    if (method && method.name) {
      const feePercentage = getFeeByMethodName(method.name);
      setSelectedFeePercentage(feePercentage);
    } else {
      setSelectedFeePercentage(null);
    }
  }, [method, availableMethodsData]);

  return (
    <div className="modal-dialog" ref={modalRef}>
      <form className="modal-content" onSubmit={handleSubmit(handleSave)}>
        <div className="modal-body">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h3>Добавить пополнение</h3>
            <div className="btn p-0" onClick={close}>
              <i className="bi bi-x fs-36 color-gray-600" />
            </div>
          </div>
          <Controller
            control={control}
            name="user"
            rules={{
              ...required,
            }}
            render={({ field, fieldState }) => (
              <InputWrapper
                className="mb-3"
                title="Клиент"
                required
                error={fieldState.error?.message}
              >
                <UserSelect placeholder="Клиент" {...field} />
              </InputWrapper>
            )}
          />
          <InputWrapper
            className="mb-3"
            title="Счёт пополнения"
            error={errors.targetCurrency?.message}
            required
          >
            <select
              className="form-select"
              {...register('targetCurrency', {
                ...required,
                onChange() {
                  resetField('topUpCurrency', { defaultValue: 'rub' });
                  resetField('method', { defaultValue: undefined });
                  resetField('feePercentage', { defaultValue: undefined });
                },
              })}
            >
              <option value="" hidden>
                Выберите счет
              </option>
              <option value="usd">USD</option>
              <option value="cny">CNY</option>
            </select>
          </InputWrapper>
          <div className="row mb-3">
            <InputWrapper
              title="Сумма пополнения"
              className="col"
              error={errors.amount?.message}
              required
            >
              <div className="input-group">
                <input
                  className="form-control"
                  type="number"
                  min={0}
                  step={0.01}
                  {...register('amount', {
                    ...required,
                    ...decimalValidation,
                  })}
                />
              </div>
            </InputWrapper>
            <InputWrapper
              className="col-3"
              title="Валюта"
              error={errors.topUpCurrency?.message}
              required
            >
              <select
                className="form-select"
                {...register('topUpCurrency', {
                  ...required,
                  onChange() {
                    resetField('method', { defaultValue: undefined });
                    resetField('feePercentage', { defaultValue: undefined });
                  },
                })}
              >
                {availableAmountCurrency.map((currency) => (
                  <option
                    key={currency}
                    value={currency}
                    children={getCurrencySymbol(currency)}
                  />
                ))}
              </select>
            </InputWrapper>
          </div>
          <InputWrapper
            title="Курс"
            className="col"
            error={errors.currencyRate?.message}
          >
            <div className="input-group">
              <input
                className="form-control"
                {...register('currencyRate', { ...decimalValidation })}
                value={selectedCurrencyRate}
                onChange={(e) => {
                  const value = e.target.value.replace(',', '.');
                  setSelectedCurrencyRate(value);
                }}
              />
            </div>
          </InputWrapper>

          {currency ? (
            <div className="bg-gray p-3 rounded-8 mb-3 mt-2">
              <div>
                Текущий курс Сайт: {getCurrencySymbol('cny')} ={' '}
                {toMoney(currency.cnyRubInternal, 'rub').replace(',', '.')}
              </div>
              <div>
                Текущий курс Сайт: {getCurrencySymbol('usd')} ={' '}
                {toMoney(currency.usdRubInternal, 'rub').replace(',', '.')}
              </div>
            </div>
          ) : null}
          <Controller
            control={control}
            name="method"
            rules={{
              ...required,
            }}
            render={({ field, fieldState }) => (
              <InputWrapper
                className="mb-3"
                title="Способ пополнения"
                required
                error={fieldState.error?.message}
              >
                <ReactSelect
                  placeholder="Способ пополнения"
                  isLoading={availableMethodsValidating}
                  options={availableMethods}
                  getOptionLabel={(option) => {
                    if (
                      option.name === 'sberbank' ||
                      option.name === 'alfabank' ||
                      option.name === 'tinkoff'
                    ) {
                      return `${option.cardNumber} / ${
                        option.cardHolderName
                      } / ${toMoney(option.remainingLimit)} из ${toMoney(
                        option.limit,
                      )} ₽`;
                    }

                    return getTopUpMethodTitle(option.name) || option.name;
                  }}
                  getOptionValue={(option) => option.id ?? option.name}
                  value={field.value ? field.value : null}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption);
                  }}
                />
              </InputWrapper>
            )}
          />
          <InputWrapper
            title="Комиссия"
            className="mb-3"
            error={errors.feePercentage?.message}
            required
          >
            <div className="input-group">
              <input
                className="form-control"
                type="number"
                min={0}
                step={0.000001}
                {...register('feePercentage', {
                  ...required,
                })}
                value={
                  selectedFeePercentage !== null ? selectedFeePercentage : ''
                }
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  setSelectedFeePercentage(value);
                }}
              />
              <span className="input-group-text">%</span>
            </div>
          </InputWrapper>
          <InputWrapper title="Сумма зачисления" className="mb-3">
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                value={calculatedTargetAmount}
                disabled
              />
              {targetCurrency && (
                <span className="input-group-text">
                  {getCurrencySymbol(targetCurrency)}
                </span>
              )}
            </div>

            <div className="fs-14 color-gray-400 mb-2">
              {method
                ? !isNaN(selectedFeePercentage ?? 0)
                  ? `С учётом комиссии ${selectedFeePercentage ?? 0} %`
                  : `С учётом комиссии 0 %`
                : `Без учета комиссии`}
            </div>
          </InputWrapper>

          <InputWrapper
            title="Комментарий для клиента"
            className="mb-3"
            error={errors.publicComment?.message}
            required
          >
            <textarea
              placeholder="Укажите здесь дополнительную информацию для клиента"
              className="form-control fs-16 lh-sm"
              {...register('publicComment', { ...required })}
            />
          </InputWrapper>
          <InputWrapper
            title="Комментарий для администратора"
            error={errors.privateComment?.message}
            required
          >
            <textarea
              placeholder="Укажите здесь дополнительную информацию для администратора"
              className="form-control fs-16 lh-sm"
              {...register('privateComment', { ...required })}
            />
          </InputWrapper>
        </div>
        <div className="modal-footer justify-content-end">
          <button type="submit" className="btn btn-success">
            Добавить
          </button>
        </div>
      </form>
    </div>
  );
};
