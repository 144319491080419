import React, { useState } from 'react';
import useSWR from 'swr';
import { api, fetcher } from '../../../services/api';

import { CleanDeliveryOrder } from '../../../export-types/cleaned-types';
import { useProfile } from '../../../api/profile';
import { FormattedMessage } from 'react-intl';
import { trpcSwr } from '../../../api/trpc';
import { IconCdek } from '../../common/icons/IconCdek';
import { AlertService } from '../../../services/alert.service';
import { Button } from 'rsuite';

type DeliveryOrderCdekInfoProps = {
  id: string;
};
export const DeliveryOrderCdekInfo: React.FC<DeliveryOrderCdekInfoProps> = (
  props,
) => {
  const { data: user } = useProfile();
  const { data: order, mutate } = useSWR<CleanDeliveryOrder>(
    `/delivery-order/${props.id}`,
    fetcher,
  );

  const { trigger: createCdek } = trpcSwr.cdek.createCdek.useSWRMutation();
  const { trigger: createReceipt } =
    trpcSwr.cdek.createReceipt.useSWRMutation();
  const { trigger: createBarcode } =
    trpcSwr.cdek.createBarcode.useSWRMutation();

  const [isCdekLoading, setIsCdekLoading] = useState<boolean>(false);

  const [isReceiptLoading, setIsReceiptLoading] = useState<boolean>(false);
  const [isBarcodeLoading, setIsBarcodeLoading] = useState<boolean>(false);

  if (
    !user ||
    !order?.localDeliveryMethod.isCdek ||
    (order?.status !== 'sentToRussia' &&
      order?.status !== 'receivedInRussia' &&
      order?.status !== 'receivedInRetailRussia' &&
      order?.status !== 'sentToRecipient')
  ) {
    return null;
  }

  if (order?.localDeliveryMethod.isCdek && !order?.cdekOrder?.cdekUuid) {
    return (
      <Button
        className="btn btn-outline-secondary align-items-center d-flex"
        loading={isCdekLoading}
        onClick={async () => {
          setIsCdekLoading(true);
          createCdek({ deliveryOrderId: order.id })
            .then(() => {
              AlertService.success('Доставка создана');
            })
            .catch((err) => {
              AlertService.error(
                err.message ? err.message : 'Что-то пошло не так',
              );
            })
            .finally(() => {
              setIsCdekLoading(false);
              mutate();
            });
        }}
      >
        Создать доставку в СДЕК
      </Button>
    );
  }

  return (
    <>
      <Button
        loading={isReceiptLoading}
        className="btn btn-outline-secondary w-30 align-items-center d-flex  "
        onClick={async () => {
          setIsReceiptLoading(true);
          if (order?.cdekOrder?.receiptUuid) {
            await api
              .get(`/cdek/receipt/${order.cdekOrder.receiptUuid}`, {
                responseType: 'blob',
              })
              .then(({ data }) => {
                const url = window.URL.createObjectURL(
                  new Blob([data], { type: 'application/pdf' }),
                );
                window.open(url, '_blank');
              })
              .catch(async (error) => {
                const data = error?.response?.data;
                if (!data) {
                  throw error;
                }
                const parsedError = JSON.parse(await data.text());
                if (parsedError?.code === 'not_ready') {
                  AlertService.success('Накладная в обработке');
                }
              })
              .finally(() => {
                mutate();
                setIsReceiptLoading(false);
              });
          } else {
            createReceipt({
              deliveryOrderId: props.id,
            })
              .then(() => {
                AlertService.success(
                  'Накладная в обработке, пожалуйста попробуйте через некоторое время еще раз',
                );
              })
              .catch(async (err) => {
                AlertService.error(err?.message || 'Произошла ошибка');
              })
              .finally(() => {
                mutate();
                setIsReceiptLoading(false);
              });
          }
        }}
      >
        {!isReceiptLoading ? <IconCdek className="w-20 mx-2" /> : null}
        <FormattedMessage
          defaultMessage="Печать накладной"
          id="DeliveryOrderCdekInfo.btn-label.createReceipt"
          description="Надпись на кнопке"
        />
      </Button>
      <Button
        loading={isBarcodeLoading}
        className={'btn btn-outline-secondary align-items-center d-flex'}
        onClick={async () => {
          setIsBarcodeLoading(true);
          if (order?.cdekOrder?.barcodeUuid) {
            await api
              .get(`/cdek/barcode/${order.cdekOrder.barcodeUuid}`, {
                responseType: 'blob',
              })
              .then(({ data }) => {
                const url = window.URL.createObjectURL(
                  new Blob([data], { type: 'application/pdf' }),
                );
                window.open(url, '_blank');
              })
              .catch(async (error) => {
                const data = error?.response?.data;
                if (!data) {
                  throw error;
                }
                const parsedError = JSON.parse(await data.text());
                if (parsedError?.code === 'not_ready') {
                  AlertService.success('Штрих-код в обработке');
                }
              })
              .finally(() => {
                mutate();
                setIsBarcodeLoading(false);
              });
          } else {
            createBarcode({
              deliveryOrderId: props.id,
            })
              .then(() => {
                AlertService.success(
                  'Штрих-код в обработке, пожалуйста попробуйте через некоторое время еще раз',
                );
              })
              .catch((err) => {
                AlertService.error(err);
              })
              .finally(() => {
                mutate();
                setIsBarcodeLoading(false);
              });
          }
        }}
      >
        <i className="bi bi-upc-scan fs-24" />
      </Button>
    </>
  );
};
